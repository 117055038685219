import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minutesToHoursPipe',
})
export class MinutesToHoursPipe implements PipeTransform {
    transform(minutes: number): string {
        const roundedMinutes = Math.round(minutes); // Round the minutes to the nearest whole number
        const hours = Math.floor(roundedMinutes / 60);
        const remainingMinutes = roundedMinutes % 60;

        return remainingMinutes && hours
            ? `${hours}h ${remainingMinutes}min`
            : hours
            ? `${hours}h`
            : `${remainingMinutes}min`;
    }
}
