import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOMINPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectboxComponent),
    multi: true,
};

@Component({
    selector: 'app-selectbox',
    templateUrl: './selectbox.component.html',
    styleUrls: ['./selectbox.component.scss'],
    providers: [CUSTOMINPUT_VALUE_ACCESSOR],
})
export class SelectboxComponent implements OnInit {
    @Input() selectItems: any[] = [];
    @Input() fieldName: string;
    @Input() fieldId: string;
    @Input() selectedFieldId: string;
    @Input() isSearchable: boolean = true;
    @Input() placeholder: string;
    @Input() controlName: string;
    @Input() formName: FormGroup;
    @Input() isRequired: boolean = false;
    @Input() requiredLableName: string;
    @Input() isMultiple: boolean = false;
    @Input() isSelectDisable: boolean = false;
    @Input() lableName: string;
    @Input() isFocus: boolean = true;
    @Input() closeOnSelect = true;
    @Input() canReturnExtraParam = false;
    @Input() hideLabel: boolean = false;
    @Input() callingFrom: string;
    public value: string;
    public changed: (value: any) => void;
    public touched: () => void;
    public isDisabled: boolean;
    public requiredClassName = '';

    @Output() onChangeSelect: EventEmitter<any> = new EventEmitter();
    @Output() onChangeSelectOtherParam: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        if (this.isRequired) {
            this.requiredClassName = 'required';
        }
    }

    get formField(): FormControl {
        return this.formName?.get(this.controlName) as FormControl;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    public onChange(event: any) {
        let value = null;
        let otherParam = null;
        if (!this.isMultiple) {
            value = event != undefined ? event.id : null;
        } else {
            value = event != undefined ? event.map((x: { id: any }) => x.id) : null;
            if (this.canReturnExtraParam && this.callingFrom == 'specility') {
                otherParam = event != undefined ? event.map((x: any) => x.name) : null;
                this.onChangeSelectOtherParam.emit(otherParam);
            } else if (this.canReturnExtraParam && this.callingFrom == 'shiftTime') {
                otherParam = event != undefined ? event.map((x: any) => x.shiftTime) : null;
                this.onChangeSelectOtherParam.emit(otherParam);
            } else if (this.canReturnExtraParam) {
                otherParam = event != undefined ? event.map((x: { isClinician: any }) => x.isClinician) : null;
                this.onChangeSelectOtherParam.emit(otherParam);
            }
        }

        this.changed = value;
        this.onChangeSelect.emit(value);
    }

    registerOnChange(fn: any): void {
        this.changed = fn;
    }

    registerOnTouched(fn: any): void {
        this.touched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onFocus() {
        this.isFocus = true;
    }

    onBlur() {
        this.isFocus = false;
    }
}
