import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LocalDateTimePipe } from 'src/app/pipes/local-date-time.pipe';
import { ChatService } from 'src/app/service/chat.service';

export class ChatModel {
    _id: number;
    text: string;
    createdAt: Date;
    date: any;
    time: any;
    image: null;
    _userId: string;
    name: string;
    avatar: any;
    isNewDay: any;
}

@Component({
    selector: 'app-chat-details',
    templateUrl: './chat-details.component.html',
    styleUrls: ['./chat-details.component.css'],
})
export class ChatDetailsComponent implements OnInit {
    skip: number = 0;
    take: number = 20;
    @Input() data: any;
    chatDetails: any;
    message: any;
    userId: any;
    chatModel: ChatModel[] = [];
    @ViewChild('scrollable') private scrollContainer: ElementRef<HTMLDivElement>;
    isLoading: boolean = false;
    wasAtBottom: boolean = true;
    noMoreMessages: boolean = false;
    messageReceivedSubscription: Subscription;

    constructor(
        private chatService: ChatService,
        private localDatePipe: LocalDateTimePipe,
        public modal: NgbActiveModal
    ) {
        this.userId = sessionStorage.getItem('userId');
        this.chatService.messageReceived.subscribe((message) => {
            this.handleReceivedMessage(message);
        });
    }

    ngOnInit() {
        let id = this.data.shiftId != null ? this.data.shiftId : this.data.jobId;
        this.joinChat(id, this.data.clinicianId);
        this.chatService.initializeConnection();

        this.loadMessages();
        this.updateChatStatus();
        this.messageReceivedSubscription = this.chatService.messageReceived.subscribe((message) => {
            this.handleReceivedMessage(message);
            this.scrollToBottom();
            // this.updateChatStatus();
        });
    }

    handleReceivedMessage(message: any) {
        if (!this.chatModel.find((msg) => msg._id === message._id)) {
            const lastMessage = this.chatModel[this.chatModel.length - 1];
            const isNewDay = lastMessage
                ? new Date(message.createdAt).toDateString() !== new Date(lastMessage.createdAt).toDateString()
                : true;

            let chatMessage: ChatModel = {
                _id: message._id,
                text: message.text,
                createdAt: message.createdAt,
                image: message.image,
                _userId: message.user._id,
                name: message.user.name,
                avatar: message.user.avatar,
                date: this.localDatePipe.transform(message.createdAt.split('Z')[0]),
                time: message.createdAt.split('Z')[0],
                isNewDay: isNewDay,
            };
            this.chatModel.push(chatMessage);
            this.scrollToBottom();
        }
    }

    ngAfterViewChecked() {
        // Adding a timeout ensures the DOM is updated before scrolling
        setTimeout(() => {
            if (this.wasAtBottom) {
                this.scrollToBottom();
            }
        }, 0);
    }

    scrollToBottom() {
        if (this.scrollContainer) {
            // Scroll to the very bottom
            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
        }
    }

    loadMessages(loadMore: boolean = false) {
        if (this.isLoading || this.noMoreMessages) return;

        this.isLoading = true;
        const params = {
            Skip: this.skip,
            Take: this.take,
            groupId: this.data.id == null ? ' ' : this.data.id,
            callingFrom: 'web',
        };

        this.chatService.getChatDetails(params).subscribe((response) => {
            if (response.result && response.result.length > 0) {
                const messages = response.result.map((element: any) => ({
                    _id: element._id,
                    text: element.text,
                    createdAt: element.createdAt,
                    image: element.image,
                    _userId: element.user._id,
                    name: element.user.name,
                    avatar: element.user.avatar,
                    date: this.localDatePipe.transform(element.createdAt),
                    time: element.createdAt,
                    isNewDay: false, // Initially false, set properly below
                }));

                // Ensure messages are in correct chronological order
                messages.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
                this.updateIsNewDayFlags(messages);

                // Add new messages to the chat model
                if (loadMore) {
                    this.chatModel = [...this.chatModel, ...messages];
                } else {
                    this.chatModel = messages;
                    this.scrollToBottom();
                }
                this.skip += this.take;
            } else {
                this.noMoreMessages = true;
            }
            this.isLoading = false;
        });
    }

    sendMessage() {
        let contactId = sessionStorage.getItem('contactId');
        let currentDate = new Date().toISOString();
        let newMessage = {
            jobId: this.data.jobId,
            shiftId: this.data.shiftId,
            clinicianId: this.data.clinicianId,
            hospitalId: this.data.hospitalId,
            contactId: contactId,
            message: this.message,
            groupsType: this.data.type == null ? 2 : this.data.type,
            date: currentDate.split('Z')[0],
        };

        if (this.message.trim() !== '') {
            this.chatService.postMessage(newMessage).subscribe((response: any) => {
                if (response.result) {
                    this.message = '';
                    this.scrollToBottom();
                }
            });
        }
    }
    onScroll(): void {
        const target = this.scrollContainer?.nativeElement;

        // A larger threshold ensures that the last message is shown completely
        this.wasAtBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - 2;

        if (target.scrollTop <= 100 && !this.isLoading && !this.noMoreMessages) {
            this.loadMessages(true);
        }
    }

    back() {
        this.modal.close(this.data.id);
    }

    joinChat(id: any, clinicianId: any) {
        this.chatService
            .joinChat(id + '_' + clinicianId)
            .then(() => {})
            .catch((err) => {
                console.log(err);
            });
    }

    // updateIsNewDayFlags(messages: ChatModel[]) {
    //     // Get today's date
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0); // Set time to 00:00:00

    //     // Assuming messages are already sorted in ascending order by createdAt
    //     if (messages.length > 0) {
    //         // Set the first message's isNewDay to true
    //         messages[0].isNewDay = true;
    //         // Loop through the rest of the messages
    //         for (let i = 1; i < messages.length; i++) {
    //             // Compare each message with the previous one
    //             messages[i].isNewDay =
    //                 new Date(messages[i].createdAt).toDateString() !==
    //                 new Date(messages[i - 1].createdAt).toDateString();
    //             // Check if the message was sent today
    //             const messageDate = new Date(messages[i].createdAt);
    //             messageDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
    //             if (messageDate.getTime() === today.getTime()) {
    //                 messages[i].date = 'Today';
    //             } else {
    //                 messages[i].date = this.customDatePipe.transform(messages[i].createdAt.toString().split('Z')[0]);
    //             }
    //         }
    //     }
    // }

    updateIsNewDayFlags(messages: ChatModel[]) {
        // Get today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to 00:00:00

        // Assuming messages are already sorted in ascending order by createdAt
        if (messages.length > 0) {
            // Set the first message's isNewDay to true
            messages[0].isNewDay = true;
            // Loop through the rest of the messages
            for (let i = 1; i < messages.length; i++) {
                // Compare each message with the previous one
                messages[i].isNewDay =
                    new Date(messages[i].createdAt).toDateString() !==
                    new Date(messages[i - 1].createdAt).toDateString();
                // Check if the message was sent today
                const messageDate = new Date(messages[i].createdAt);
                messageDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
                if (messageDate.getTime() === today.getTime()) {
                    messages[i].date = 'Today';
                } else {
                    messages[i].date = this.localDatePipe.transform(messages[i].createdAt.toString().split('T')[0]);
                }
            }
        }
    }

    updateChatStatus() {
        // this.chatService.upateChatStatus(this.data.id).subscribe((response: any) => {
        //     if (response.result) {
        //         console.log(response.result);
        //     }
        // });
    }
}
