import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class DataTableRequestModelService {
    constructor(private apiService: ApiService) {}

    dataTableRequestService(apiUrl: any, req: any): Observable<any> {
        return this.apiService.get(apiUrl + '?Skip=' + req.Skip + '&Take=' + req.Take + '&OrderBy=' + req.OrderBy);
    }
    dataTableRequestServiceForStaff(apiUrl: any, req: any): Observable<any> {
        return this.apiService.get(
            apiUrl +
                '?Skip=' +
                req.Skip +
                '&Take=' +
                req.Take +
                '&searchText=' +
                req.searchText +
                '&orderBy=' +
                req.orderBy
        );
    }

    dataTableRequestServiceForJob(apiUrl: any, req: any): Observable<any> {
        if (req.jobPendingApplications) {
            return this.apiService.get(
                apiUrl +
                    '?Skip=' +
                    req.Skip +
                    '&Take=' +
                    req.Take +
                    '&searchText=' +
                    req.searchText +
                    '&OrderBy=' +
                    req.OrderBy +
                    '&marketId=' +
                    req.marketId +
                    '&companyId=' +
                    req.companyId +
                    '&hospitalId=' +
                    req.hospitalId +
                    '&licenseTypeIds= ' +
                    req.licenseTypeIds +
                    '&jobStatus=' +
                    req.jobStatus +
                    '&specialityIds=' +
                    req.specialityIds +
                    '&jobPendingApplications=' +
                    req.jobPendingApplications
            );
        } else {
            return this.apiService.get(
                apiUrl +
                    '?Skip=' +
                    req.Skip +
                    '&Take=' +
                    req.Take +
                    '&searchText=' +
                    req.searchText +
                    '&OrderBy=' +
                    req.OrderBy +
                    '&marketId=' +
                    req.marketId +
                    '&companyId=' +
                    req.companyId +
                    '&hospitalId=' +
                    req.hospitalId +
                    '&licenseTypeIds= ' +
                    req.licenseTypeIds +
                    '&jobStatus=' +
                    req.jobStatus +
                    '&specialityIds=' +
                    req.specialityIds
            );
        }
    }
    dataTableRequestServiceForPipeline(apiUrl: any, req: any): Observable<any> {
        return this.apiService.get(
            apiUrl + '?Skip=' + req.Skip + '&Take=' + req.Take + '&searchText=' + req.searchText + '&id=' + req.id
        );
    }

    dataTableRequestServiceForChat(apiUrl: any, req: any): Observable<any> {
        return this.apiService.get(
            apiUrl +
                '?Skip=' +
                req.Skip +
                '&Take=' +
                req.Take +
                '&OrderBy=' +
                'createdOn desc' +
                '&type=' +
                req.type +
                '&callingFrom=' +
                'web' +
                '&chatGroupId=' +
                req.chatGroupId
        );
    }
    dataTableRequestServiceForChatDetails(apiUrl: any, req: any): Observable<any> {
        return this.apiService.get(
            apiUrl +
                '?Skip=' +
                req.Skip +
                '&Take=' +
                req.Take +
                '&OrderBy=' +
                'date desc' +
                '&groupId=' +
                req.groupId +
                '&callingFrom=' +
                req.callingFrom
        );
    }

    dataTableRequestServiceForShiftList(apiUrl: any, req: any): Observable<any> {
        return this.apiService.get(
            apiUrl +
                '?Skip=' +
                req.Skip +
                '&Take=' +
                req.Take +
                '&hospitalId=' +
                req.hospitalId +
                '&unitId=' +
                req.unitId +
                '&jobIds=' +
                req.jobIds +
                '&licenseTypeIds=' +
                req.jobTypeIds +
                '&shiftType=' +
                req.shiftType +
                '&shiftManagerIds=' +
                req.shiftManagerIds +
                '&fromDate=' +
                req.fromDate +
                '&toDate=' +
                req.toDate +
                '&status=' +
                req.status
        );
    }
    dataTableRequestServiceForJobFilter(apiUrl: any, req: any, search: any, jobTypeId: any): Observable<any> {
        return this.apiService.get(
            apiUrl + '?Skip=' + req.Skip + '&Take=' + req.Take + '&search=' + search + 'filterJob=' + jobTypeId
        );
    }

    //Shift Inspector
    dataTableRequestServiceForShiftInspector(apiUrl: any, req: any): Observable<any> {
        return this.apiService.get(
            apiUrl +
                '?Skip=' +
                req.Skip +
                '&Take=' +
                req.Take +
                '&marketId=' +
                req.marketId +
                '&hospitalId=' +
                req.hospitalId +
                '&companyId=' +
                req.companyId +
                '&status=' +
                req.status +
                '&date=' +
                req.date +
                '&fromTime=' +
                req.fromTime +
                '&toTime=' +
                req.toTime +
                '&facilityId=' +
                req.facilityId +
                '&searchText=' +
                req.searchText +
                '&orderBy=' +
                req.orderBy +
                '&licenseTypeIds=' +
                req.licenseTypeIds +
                '&specialityIds=' +
                req.specialityIds +
                '&fromDate=' +
                req.fromDate +
                '&toDate=' +
                req.toDate +
                '&periods=' +
                req.periods +
                '&totalHours=' +
                req.totalHours +
                '&timeZone=' +
                req.timeZone
        );
    }

    dataTableRequestAvailableNurses(apiUrl: any, req: any): Observable<any> {
        return this.apiService.get(
            apiUrl +
                '?Skip=' +
                req.Skip +
                '&Take=' +
                req.Take +
                '&searchText=' +
                req.searchText +
                '&ShiftId=' +
                req.ShiftId +
                '&NurseLevel=' +
                req.NurseLevel +
                '&shiftPositionId=' +
                req.shiftPositionId
        );
    }
}
