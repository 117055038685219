export class AssetsModel {
    constructor() {
        this.id = 0;
        this.name = null;
        this.displayText = null;
        this.url = null;
        this.parentAssetsId = null;
        this.isActive = false;
        this.isLabel = false;
        this.position = null;
        this.descrption = null;
        this.roleId = null;
        this.iconClass = null;
        this.iconColor = null;
        this.assetLevel = null;
        this.menuFor = null;
        this.isItComingFromRealData = false;
    }

    id: number;
    name: any;
    displayText: any;
    url: any;
    parentAssetsId: any;
    isActive: boolean;
    isLabel: any;
    position: any;
    descrption: any;
    roleId: any;
    iconClass: any;
    iconColor: any;
    assetLevel: any;
    menuFor: any;
    isItComingFromRealData: boolean;

    setContactData(Assets: any) {
        this.id = Assets.id;
        this.name = Assets.name;
        this.displayText = Assets.displayText;
        this.url = Assets.url;
        this.parentAssetsId = Assets.parentAssetsId;
        this.isActive = Assets.isActive;
        this.isLabel = Assets.isLabel;
        this.position = Assets.position;
        this.descrption = Assets.descrption;
        this.roleId = Assets.roleId;
        this.iconClass = Assets.iconClass;
        this.iconColor = Assets.iconColor;
        this.assetLevel = Assets.assetLevel;
        this.isItComingFromRealData = Assets.isItComingFromRealData;
    }
}
export enum AssetLevel {
    First = 1,
    Second = 2,
    Third = 3,
    Fourth = 4,
}

export enum MenuFor {
    All = 1,
    Thirve = 2,
    Healthcare = 3,
}
