import { Component, EventEmitter, Input, OnInit, Output, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { StatusChangeService } from 'src/app/service/statusChange.service';

@Component({
    selector: 'app-delete-model',
    templateUrl: './delete-model.component.html',
    styleUrls: ['./delete-model.component.scss'],
})
export class DeleteModelComponent implements OnInit {
    @Output() updatedAddress: EventEmitter<any> = new EventEmitter();

    @Input() moduleName = null;
    @Input() moduleURL = null;
    @Input() isDelete = null;
    @Input() id = null;
    @Input() status: any;
    @Input() data: any = null;
    @Input() jobApplicantId: any;
    message: any;
    subscriptions: Subscription[] = [];
    constructor(
        public modal: NgbActiveModal,
        private toastrService: ToastrService,
        private ngxSpinnerService: NgxSpinnerService,
        private statusChangeService: StatusChangeService
    ) {}

    ngOnInit(): void {
        this.message = this.isDelete
            ? 'Are you sure you want to Delete' + ' ' + this.moduleName + '?'
            : this.status == true
            ? this.moduleName == 'Document'
                ? 'Are you sure you want to validate' + ' ' + this.moduleName + '?'
                : 'Are you sure you want to Activate' + ' ' + this.moduleName + '?'
            : 'Are you sure you want to Inactivate' + ' ' + this.moduleName + '?';
    }

    callBack() {
        if (this.isDelete) {
            this.delete();
        } else {
            this.updateStatus();
        }
    }

    delete() {
        this.ngxSpinnerService.show();
        this.statusChangeService.delete(this.moduleURL, this.id).subscribe(
            (response: any) => {
                if (response.result == true) {
                    this.modal.close();
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err: any) => {
                this.toastrService.error(err.error.message);
            }
        );
    }

    updateStatus() {
        this.ngxSpinnerService.show();
        if (this.data?.jobApplicantId) {
            this.data.jobApplicantId = this.jobApplicantId;
        }
        this.statusChangeService.updateStatus(this.moduleURL, this.data).subscribe(
            (response: any) => {
                if (response.result == true) {
                    this.modal.close();
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                    this.ngxSpinnerService.hide();
                }

                this.ngxSpinnerService.hide();
            },
            (err: any) => {
                this.toastrService.error(err.error.message);
            }
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
    }
}
