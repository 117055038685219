import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditCityModelComponent } from './add-edit-city-model/add-edit-city-model.component';
import { DataTableModel, DisplayBlock } from 'src/app/providers/classes/helper.classes';
import { TitleCasePipe } from '@angular/common';
import { DeleteModelComponent } from 'src/app/shared/components/delete-model/delete-model.component';
import { API_REGION } from 'src/app/constants/api';
import { EllipsisPipe } from 'src/app/pipes/ellipsis.pipe';
import { RowClickHandlerService } from 'src/app/service/rowClickHandlerService.service';

@Component({
    selector: 'app-city',
    templateUrl: './city.component.html',
    styleUrls: ['./city.component.scss'],
    providers: [TitleCasePipe, EllipsisPipe],
})
export class CityComponent implements OnInit {
    public dataTableModel: DataTableModel = new DataTableModel();
    isActive: any = '';
    displayBlock!: DisplayBlock;

    constructor(private modalService: NgbModal, private rowClickHandlerService: RowClickHandlerService) {}

    ngOnInit(): void {
        this.dataTableBinding(this.isActive);
    }

    onSelectChange(selectedOption: any) {
        this.displayBlock = {
            isActive: selectedOption == '' ? '' : JSON.parse(selectedOption),
            isLoadData: true,
        };
    }

    dataTableBinding(isActive: any) {
        let that = this;

        this.displayBlock = {
            getUrl: 'cities/list-of-cities',
            isServerSidePagination: true,
            isQueryParam: false,
            queryparam: '',
            isActive: this.isActive ?? null,
            isAllowPaging: true,
            sortOrder: [[0, 'asc']],
            isShowPaginationInfo: true,
            isSearching: true,
            rows: [
                {
                    name: 'stateName',
                    label: 'US State',
                    type: 'text',
                    colSize: '10%',
                    sorting: true,
                    pipe: 'title',
                },
                {
                    name: 'name',
                    label: 'Name',
                    type: 'text',
                    colSize: '80%',
                    sorting: true,
                    pipe: 'title',
                },
                {
                    name: 'action',
                    label: 'Actions',
                    type: 'action',
                    colSize: '10%',
                    actions: {
                        isEdit: true,
                        isDeactivate: false,
                        isDelete: false,
                    },
                },
            ],
            options: {
                add: true,
                clear: false,
                delete: true,
                search: true,
                export: false,
                active: true,
            },
        };
        this.dataTableModel.displayBlock = this.displayBlock;
    }
    create() {
        this.addEdit(null);
    }
    rowCallback(event: any) {
        let that = this;
        const _row = $(event.row);
        _row.find('[action-type="edit"]')
            .off('click')
            .on('click', function () {
                that.addEdit(event.data);
            });

        _row.find('[action-type="delete"]')
            .off('click')
            .on('click', function () {
                that.delete(event.data.id);
            });

        _row.find('[action-type="active"]')
            .off('click')
            .on('click', function () {
                that.updateStatus(event.data.id, event.data.isActive == false ? true : false);
            });
        if (_row.length) {
            // Use the service to handle the row click logic
            this.rowClickHandlerService.handleRowClick(_row, event, 2, this.onRowClick.bind(this));
        }
    }
    addEdit(model: any) {
        this.dataTableModel.isLoadData = false;
        const modalRef = this.modalService.open(AddEditCityModelComponent, {
            size: 'l',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.editcityModel = model;
        modalRef.result.then(
            () => (this.dataTableModel.isLoadData = true),
            () => {}
        );
    }

    delete(id: any) {
        this.dataTableModel.isLoadData = false;

        const modalRef = this.modalService.open(DeleteModelComponent, {
            size: 'l',
            backdrop: false,
        });
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.moduleName = 'City';
        modalRef.componentInstance.moduleURL = API_REGION.DELETE_CITIES;
        modalRef.componentInstance.isDelete = true;
        modalRef.result.then(
            () => (this.dataTableModel.isLoadData = true),
            () => {}
        );
    }

    updateStatus(id: any, status: boolean) {
        this.dataTableModel.isLoadData = false;

        const modalRef = this.modalService.open(DeleteModelComponent, {
            size: 'l',
            backdrop: false,
        });
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.moduleName = 'City';
        modalRef.componentInstance.moduleURL = API_REGION.ACTIVE_DEACTIVE_CITY + id;
        modalRef.componentInstance.isDelete = false;
        modalRef.componentInstance.status = status;
        modalRef.result.then(
            () => (this.dataTableModel.isLoadData = true),
            () => {}
        );
    }

    onRowClick(data: any): void {
        // Handle the valid click based on the logic
        this.addEdit(data);
    }
}
