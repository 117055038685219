<!-- <div class="row">
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="card-title d-flex justify-content-between mb-0">
          <div class=" d-flex align-items-center">
            <h3 class="card-label">{{title}}</h3>
          </div>
          <div class="d-flex align-items-center me-n1">
            <button
              type="button"
              class="btn btn-orange"
              (click)="createRecord()"
            >
              New {{ title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="row">
    <div class="col-md-12 grid-margin stretch-card">
        <button type="button" class="btn btn-orange create-btn" (click)="createRecord()">
            {{ hasNewNotAdded ? '' : 'New ' }}{{ title }}
        </button>
        <!-- <div class="d-flex justify-content-between mb-0">
      <div class="d-flex align-items-center me-n1">
        
      </div>
    </div> -->
    </div>
</div>
