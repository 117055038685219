<form
    class="form form-label-right"
    novalidate="novalidate"
    id="kt_category_form"
    [formGroup]="cityForm"
    #cityFormDirective="ngForm"
    (ngSubmit)="submit()"
    [appUnsavedChanges]="cityForm"
    [hasFormChanged]="hasFormChanged.bind(this)"
>
    <div class="modal-content fixwidth">
        <div class="modal-header">
            <div class="modal-title h4" id="example-modal-sizes-title-lg">
                <ng-container *ngIf="!this.editcityModel"> Add US City </ng-container>
                <ng-container *ngIf="this.editcityModel"> Edit US City </ng-container>
            </div>
        </div>

        <div class="overlay overlay-block cursor-default modal-body dropdown-box">
            <app-selectbox
                class="dropdown-box"
                formControlName="stateId"
                [selectItems]="states"
                fieldName="name"
                fieldId="id"
                placeholder="Select US State"
                [isSearchable]="true"
                [selectedFieldId]="selectedStateId"
                [formName]="cityForm"
                (onChangeSelect)="onStateChange($event)"
                requiredLableName="US State"
                [isRequired]="true"
                controlName="stateId"
                [isMultiple]="false"
                lableName="US State"
            >
            </app-selectbox>

            <div class="form-group mt-3">
                <app-inputbox
                    type="text"
                    formControlName="name"
                    [parentForm]="cityForm"
                    label="City Name"
                    [isWhiteSpace]="true"
                    [min]="1"
                    fieldName="name"
                    [isRequired]="true"
                ></app-inputbox>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary fw-semibold mr-2" (click)="dismissModel()">
                Cancel
            </button>

            <ng-container>
                <button type="submit" [disabled]="cityForm.invalid || !hasFormChanged()" class="btn btn-orange btn-elevate">
                    Save US City
                </button>
            </ng-container>
        </div>
    </div>
</form>
