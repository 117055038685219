import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {}
    canActivate() {
        let data: any = sessionStorage.getItem('userDetail');
        if (data != '' && data != undefined && data != null) {
            let userInfo = JSON.parse(data);

            let token = userInfo.token;
            let refreshToken = userInfo.refreshToken;
            let tokenValidTo = new Date(userInfo.tokenValidTo);
            let currentDateTime = new Date();

            if (token && tokenValidTo.getTime() > currentDateTime.getTime()) {
                return true;
            } else if (token && currentDateTime.getTime() > tokenValidTo.getTime()) {
                let obj = {
                    token: token,
                    refreshToken: refreshToken,
                };

                this.authService.refreshToken(obj).subscribe(
                    (response) => {
                        const newToken = response.newToken;
                        userInfo.token = newToken;
                    },
                    (error) => {
                        location.reload();
                        this.authService.logout();
                    }
                );

                return true;
            }
        }

        this.authService.logout();
        return false;
    }
}
