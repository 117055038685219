<div
    [@slideInOut]="sidebarState"
    class="sidebar"
    *ngIf="!isItComingFromRealData && getAssetName != 'nurse-profile' && getAssetName != 'add-nurse'"
>
    <button (click)="toggleSidebar()" class="slidbarBtn">
        <span class="material-icons">keyboard_arrow_left</span>
    </button>
    <ng-scrollbar class="scroll-event">
        <nz-tree [nzData]="treeNodes" nzShowLine [(nzSelectedKeys)]="selectedKey" (nzClick)="onNodeClick($event)">
            <ng-template #nzTreeTemplate let-node class="item">
                <span class="menu-title">{{ node.title }}</span>
            </ng-template>
        </nz-tree>
    </ng-scrollbar>
</div>

<div
    [@slideInOut]="sidebarState"
    class="sidebar sidebar-tab"
    *ngIf="
        isItComingFromRealData &&
        !['/lead-nurse', '/restricted-nurse', '/active-nurse', '/former-nurses', '/add-nurse'].includes(
            secondLevelAssetUrl
        ) &&
        getAssetName != 'nurse-profile' &&
        getAssetName != 'add-nurse'
    "
>
    <button (click)="toggleSidebar()" class="slidbarBtn">
        <span class="material-icons">keyboard_arrow_left</span></button
    >{{ getAssetName }}

    <ng-scrollbar class="scroll-event">
        <!-- Content based on the active tab -->
        <ng-container>
            <!-- Tree menu content -->
            <nz-tree
                class="scrollbar-tree"
                [nzData]="treeNodes"
                nzShowLine
                [(nzSelectedKeys)]="selectedKey"
                (nzClick)="onNodeClickForRealData($event)"
            >
                <ng-template #nzTreeTemplate let-node>
                    <span class="menu-title">{{ node.title }}{{ getAssetName }}</span>
                </ng-template>
            </nz-tree>
        </ng-container>
    </ng-scrollbar>
</div>
