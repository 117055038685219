// import { Directive, HostListener } from '@angular/core';
// import { NgControl } from '@angular/forms';

// @Directive({
//     selector: '[appPhoneMask]',
// })
// export class PhoneMaskDirective {
//     constructor(public ngControl: NgControl) {}

//     @HostListener('ngModelChange', ['$event'])
//     onModelChange(event: any) {
//         this.onInputChange(event, false);
//     }

//     @HostListener('keydown.backspace', ['$event'])
//     keydownBackspace(event: any) {
//         this.onInputChange(event.target.value, true);
//     }

//     onInputChange(event: any, backspace: any) {
//         let newVal = event.replace(/\D/g, '');
//         if (backspace && newVal.length <= 6) {
//             newVal = newVal.substring(0, newVal.length - 1);
//         }
//         if (newVal.length === 0) {
//             newVal = '';
//         } else if (newVal.length <= 3) {
//             newVal = newVal.replace(/^(\d{0,3})/, '($1)');
//         } else if (newVal.length <= 6) {
//             newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
//         } else if (newVal.length <= 10) {
//             newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
//         } else {
//             newVal = newVal.substring(0, 10);
//             newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
//         }
//         this.ngControl.valueAccessor?.writeValue(newVal);
//     }
// }

import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appPhoneMask]', // Update selector to match your usage
})
export class PhoneMaskDirective implements OnInit {
    constructor(private ngControl: NgControl) {}

    ngOnInit() {
        if (!this.ngControl) {
            console.error('NgControl not found. Make sure the directive is used within a form control.');
            return;
        }
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string) {
        if (!value) return;
        let newVal = value.replace(/\D/g, '');
        if (newVal.length === 0) {
            this.ngControl.control?.setValue('');
        } else if (newVal.length <= 3) {
            this.ngControl.control?.setValue('(' + newVal + ')');
        } else if (newVal.length <= 6) {
            this.ngControl.control?.setValue('(' + newVal.slice(0, 3) + ') ' + newVal.slice(3));
        } else if (newVal.length <= 10) {
            this.ngControl.control?.setValue(
                '(' + newVal.slice(0, 3) + ') ' + newVal.slice(3, 6) + '-' + newVal.slice(6)
            );
        } else {
            newVal = newVal.slice(0, 10);
            this.ngControl.control?.setValue(
                '(' + newVal.slice(0, 3) + ') ' + newVal.slice(3, 6) + '-' + newVal.slice(6)
            );
        }
    }
}
