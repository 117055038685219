import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-Confirmation-model',
    templateUrl: './Confirmation-model.component.html',
    styleUrls: ['./Confirmation-model.component.scss'],
})
export class ConfirmationModelComponent implements OnInit {
    @Output() getInheritType: EventEmitter<any> = new EventEmitter();
    @Input() moduleName = null;
    @Input() inherit = null;

    constructor(public modal: NgbActiveModal) {}

    ngOnInit() {}

    callBack(event: any) {
        this.modal.close(event);
    }
}
