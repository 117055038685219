import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { API_AUTH } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private apiService: ApiService,
        private router: Router
    ) {}

    login(res: any): Observable<any> {
        return this.apiService.post(API_AUTH.POST_AUTHENTICATE, res);
    }

    refreshToken(req: any): Observable<any> {
        return this.apiService.post(API_AUTH.POST_REFRESH_TOKEN, req);
    }

    logout() {
        sessionStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/auth/login']);
    }
    forgotPasword(res: any): Observable<any> {
        return this.apiService.post(API_AUTH.POST_FORGOT_PASSWORD, res);
    }
    resetPassword(res: any): Observable<any> {
        return this.apiService.post(API_AUTH.POST_RESET_PASSWORD, res);
    }
    changePassword(res: any): Observable<any> {
        return this.apiService.post(API_AUTH.POST_CHANGE_PASSWORD, res);
    }
    emailVarification(res: any): Observable<any> {
        return this.apiService.put(API_AUTH.PUT_EMAIL_CONFIRMATION, res);
    }
    getDetail(): Observable<any> {
        return this.apiService.get(API_AUTH.GET_USERS);
    }
    resetContact(res: any): Observable<any> {
        return this.apiService.post(API_AUTH.POST_CONTACT_ADMIN, res);
    }
    passwordValidation(res: any): Observable<any> {
        return this.apiService.post(API_AUTH.POST_USER_PASSWORD_VALIDATION, res);
    }
}
