<label *ngIf="label" class="form-label">{{ label }}<span *ngIf="isRequired" class="required"></span></label>
<textarea
    *ngIf="!placeholder"
    [class]="class"
    [name]="fieldName"
    [id]="fieldName"
    [disabled]="isDisabled"
    [value]="value"
    (input)="onChange($event)"
    autocomplete="off"
    [placeholder]="label"
    [maxLength]="max"
    [minLength]="min"
    [rows]="rows"
    [cols]="cols"
></textarea>
<textarea
    *ngIf="placeholder"
    [class]="class"
    [name]="fieldName"
    [id]="fieldName"
    [disabled]="isDisabled"
    [value]="value"
    (input)="onChange($event)"
    autocomplete="off"
    [placeholder]="placeholder"
    [maxLength]="max"
    [minLength]="min"
    [rows]="rows"
    [cols]="cols"
></textarea>
<p class="text-secondary" *ngIf="isShowCharacterCount && value?.length > 0">
    <small> Character limit: {{ value?.length ? value?.length : 0 }} / {{ max }} </small>
</p>

<p class="input-error" *ngIf="min > itemLength && isMinimum">Enter minimum {{ min }} characters</p>

<ng-container
    *ngIf="parentForm?.get(fieldName)?.errors?.required"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'required',
        message: label + ' is required',
        control: parentForm.controls[fieldName]
    }"
></ng-container>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.invalid && control.touched" class="error-message">
        <div class="fv-plugins-message-container">
            <div class="input-error">
                {{ message }}
            </div>
        </div>
    </ng-container>
</ng-template>
