import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { API_AUTH, API_COMPANIES, API_NOTIFICATION, API_UNITS } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private apiService: ApiService) {}
    addNotification(res: any): Observable<any> {
        return this.apiService.post('Notifications/send-thrive-admin-notification', res);
    }
    getCompany(companyId: any): Observable<any> {
        return this.apiService.get(API_COMPANIES.GET_COMPAYIES_SELECTED_LIST + '?companyId=' + companyId);
    }
    getHospital(): Observable<any> {
        return this.apiService.get('hospitals/hospital-select-list');
    }
    getHospitalId(id: any): Observable<any> {
        return this.apiService.get('hospitals/hospital-select-list?companyId=' + id);
    }
    getDepartment(): Observable<any> {
        return this.apiService.get(API_UNITS.GET_UNITS_SELECTED_LIST);
    }
    getDepartmentId(id: any): Observable<any> {
        return this.apiService.get(API_UNITS.GET_UNITS_SELECTED_LIST + '?hospitalId=' + id);
    }
    getStaff(): Observable<any> {
        return this.apiService.get(API_AUTH.GET_USER_SELECTED_LIST);
    }
    getStaffId(id: any): Observable<any> {
        return this.apiService.get(API_AUTH.GET_USER_SELECTED_LIST + id);
    }

    getNotificationList(requestModal: any): Observable<any> {
        return this.apiService.post(API_NOTIFICATION.GET_NOTIFICATIONS, requestModal);
    }

    updateNotificationStatus(req: any): Observable<any> {
        return this.apiService.put(API_NOTIFICATION.UPDATE_NOTIFICATION_READ_STATUS, req);
    }

    deleteNotification(req: any): Observable<any> {
        return this.apiService.put(API_NOTIFICATION.DELETE_NOTIFICATION, req);
    }

    getNotificationCount(requestModal: any): Observable<any> {
        return this.apiService.post(API_NOTIFICATION.GET_NOTIFICATION_COUNT, requestModal);
    }
}
