import moment from 'moment';
import { BreakTimeMinutes } from '../model/shift.model';

export function formatPhoneNumber(phoneNumber: string): string {
    return phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
}

export function weekDays() {
    return [
        { id: 'Sunday', name: 'Sunday' },
        { id: 'Monday', name: 'Monday' },
        { id: 'Tuesday', name: 'Tuesday' },
        { id: 'Wednesday', name: 'Wednesday' },
        { id: 'Thursday', name: 'Thursday' },
        { id: 'Friday', name: 'Friday' },
        { id: 'Saturday', name: 'Saturday' },
    ];
}

export function getBreakTimeMinutes(breakTimeId: number): number {
    const breakTimeMap: { [key in BreakTimeMinutes]: number } = {
        [BreakTimeMinutes['15 Minutes']]: 15,
        [BreakTimeMinutes['20 Minutes']]: 20,
        [BreakTimeMinutes['25 Minutes']]: 25,
        [BreakTimeMinutes['30 Minutes']]: 30,
        [BreakTimeMinutes['60 Minutes']]: 60,
    };

    return breakTimeMap[breakTimeId as keyof typeof breakTimeMap] || 0;
}

export function calculateTotalHours(fromTime: any, toTime: any, breakTime: any, isBreakTimePaid: any): any {
    const isoStartTime = moment(fromTime); // Directly parse ISO datetime
    const isoEndTime = moment(toTime);

    // Adjust end time if it's on the next day
    if (isoEndTime.isBefore(isoStartTime)) {
        isoEndTime.add(1, 'day');
    }

    let differenceInMinutes = isoEndTime.diff(isoStartTime, 'minutes');

    // Deduct break time only if unpaid
    if (!isBreakTimePaid && breakTime) {
        const breakMinutes = getBreakTimeMinutes(breakTime);
        differenceInMinutes -= breakMinutes;
    }

    const hours = Math.floor(differenceInMinutes / 60);
    const minutes = differenceInMinutes % 60;
    const totalHours = `${hours}.${String(minutes).padStart(2, '0')}`; // Ensure 2-digit minutes

    return totalHours;
}

export function calculateTotalHoursOnBreakTime(
    fromTime: any,
    toTime: any,
    breakTime: any,
    isBreakTimePaid: any
): string {
    const isoStartTime = moment(fromTime, 'hh:mm:00 A')
        .set({ year: 2024, month: 0, date: 1, second: 0 })
        .startOf('minute');

    const isoEndTime = moment(toTime, 'hh:mm:00 A').set({ year: 2024, month: 0, date: 1, second: 0 }).startOf('minute');

    // Adjust end time if it occurs on the next day
    if (isoEndTime.isBefore(isoStartTime)) {
        isoEndTime.add(1, 'day');
    }

    let differenceInMinutes = moment.duration(isoEndTime.diff(isoStartTime)).asMinutes();

    // Deduct break time only if unpaid (isBreakTimePaid is false)
    if (!isBreakTimePaid && breakTime) {
        const breakMinutes = getBreakTimeMinutes(breakTime);
        differenceInMinutes -= breakMinutes;
    }
    const hours = Math.floor(differenceInMinutes / 60);
    const minutes = Math.floor(differenceInMinutes % 60);
    const totalHours = `${hours}.${String(minutes).padStart(2, '0')}`; // Add leading zero if minutes are < 10

    return `${totalHours}`;
}

export const ShiftStatus = [
    { id: 'OpenShift', name: 'Open Shift' },
    { id: 'InviteSent', name: 'Invite Sent' },
    { id: 'Scheduled', name: 'Scheduled' },
    { id: 'InProgress', name: 'In Progress' },
    { id: 'Completed', name: 'Completed' },
    { id: 'Released', name: 'Released' },
    { id: 'Cancel', name: 'Cancel' },
    { id: 'Unfilled', name: 'Unfilled' },
];
export function formatHoursMinutes(hours: number): string {
    const hrs = Math.floor(hours); // Extract hours (4 from 4.08)
    const mins = Math.round((hours % 1) * 100); // Extract minutes (08 from 4.08)

    return hrs > 0 && mins > 0 ? `${hrs}h ${mins}min` : hrs > 0 ? `${hrs}h` : `${mins}min`;
}

export function isTimePassedWithBuffer(toTime: string, timezoneName: string, bufferMinutes?: number): boolean {
    if (!toTime || !timezoneName) return false;

    // Step 1: Convert to UTC moment and add 30 minutes
    const updatedUtcTime = moment.utc(toTime).add(bufferMinutes, 'minutes');

    // Step 2: Convert to the specified timezone
    const convertedTime = updatedUtcTime.tz(timezoneName);

    // Step 3: Get current time in the same timezone
    const nowInTimezone = moment().tz(timezoneName);

    // Step 4: Check if the adjusted time is in the past
    return convertedTime.isBefore(nowInTimezone);
}

export const sortDegrees = (EducationData: any) => {
    if (!EducationData) {
        return [];
    }

    // Separate In-Progress and Completed Degrees
    const inProgressDegrees: any[] = [];
    const completedDegrees: any[] = [];

    EducationData?.forEach((degree: any) => {
        if (!degree.endDate) {
            inProgressDegrees.push(degree);
        } else {
            completedDegrees.push(degree);
        }
    });

    // Sort In-Progress Degrees by Start Date (Most Recent First)
    inProgressDegrees?.sort((a, b) => moment(b.startDate).diff(moment(a.startDate)));

    // Sort Completed Degrees by End Date (Most Recent First)
    // completedDegrees?.sort((a, b) => moment(b.endDate).diff(moment(a.endDate)));

    completedDegrees.sort((a, b) => {
        const endDateDiff = moment(b.endDate).diff(moment(a.endDate));

        // If endDate is the same, sort by startDate in descending order
        if (endDateDiff === 0) {
            return moment(b.startDate).diff(moment(a.startDate));
        }

        return endDateDiff;
    });

    // Merge the sorted lists (In-Progress first, then Completed)
    return [...inProgressDegrees, ...completedDegrees];
};

export function getFacilityId() {
    let facilityId: any = sessionStorage.getItem('hospitalId');
    return facilityId == null || facilityId == '' ? null : JSON.parse(facilityId);
}

export const HiringProcess = [
    { id: 1, name: 'Regular Hire' },
    { id: 2, name: 'Priority Hire' },
];

export function getLocalData() {
    let isThriveLevelLogin: any = sessionStorage.getItem('isThriveLevelLogin');
    let isAgencyLevelLogin: any = sessionStorage.getItem('isAgencyLevelLogin');
    let isCompanyLevelLogin: any = sessionStorage.getItem('isCompanyLevelLogin');
    let isFacilityLevelLogin: any = sessionStorage.getItem('isFacilityLevelLogin');
    let hospitalStaffPolicy: any = sessionStorage.getItem('hospitalStaffPolicy');
    let selectedhospitalStaffPolicy: any = sessionStorage.getItem('selectedhospitalStaffPolicy');
    let detail = {
        isCompanyLevelLogin: JSON.parse(isCompanyLevelLogin),
        isFacilityLevelLogin: JSON.parse(isFacilityLevelLogin),
        isThriveLevelLogin: JSON.parse(isThriveLevelLogin),
        isAgencyLevelLogin: JSON.parse(isAgencyLevelLogin),
        hospitalStaffPolicy: JSON.parse(hospitalStaffPolicy),
        selectedhospitalStaffPolicy: JSON.parse(selectedhospitalStaffPolicy),
    };
    return detail;
}

export function canPostJob(hospitalId: any, callingFrom?: any): boolean {
    let localData = getLocalData();
    if (localData.isCompanyLevelLogin || localData.isFacilityLevelLogin) {
        if (hospitalId && !callingFrom) {
            // Find the hospital entry matching the given hospitalId
            const hospitalData = localData.hospitalStaffPolicy.find((h: any) => h.hospitalId === hospitalId);
            return hospitalData.canPostLevel1Job || hospitalData.canPostLevel2Job;
        } else if (callingFrom) {
            const hospitalData = localData.selectedhospitalStaffPolicy;
            return hospitalData.canPostLevel1Job || hospitalData.canPostLevel2Job;
        }
    }

    return true; // Default behavior for other user levels
}

export function canPostShift(hospitalId: any, callingFrom?: any): boolean {
    let localData = getLocalData();
    if (localData.isCompanyLevelLogin || localData.isFacilityLevelLogin) {
        if (hospitalId && !callingFrom) {
            // Find the hospital entry matching the given hospitalId
            const hospitalData = localData.hospitalStaffPolicy.find((h: any) => h.hospitalId === hospitalId);
            return hospitalData.canPostLevel1Shift || hospitalData.canPostLevel2Shift;
        } else if (callingFrom) {
            const hospitalData = localData.selectedhospitalStaffPolicy;

            return (
                hospitalData?.isLevel3Configured || hospitalData?.canPostLevel1Shift || hospitalData?.canPostLevel2Shift
            );
        }
    }

    return true; // Default behavior for other user levels
}

export function canNurseApplyOnJob(hospitalId: any): boolean {
    let localData = getLocalData();
    if ((localData.isCompanyLevelLogin || localData.isFacilityLevelLogin) && hospitalId) {
        // Find the hospital entry matching the given hospitalId
        const hospitalData = localData.hospitalStaffPolicy.find((h: any) => h.hospitalId === hospitalId);
        return hospitalData.canNurseApplytoLevel1Job || hospitalData.canNurseApplytoLevel2Job;
    }

    return true; // Default behavior for other user levels
}

export function getFacilityLevelSettings(hospitalId: number): string[] {
    let localData = getLocalData();
    let facilityLevels: string[] = [];

    if (
        (localData.isCompanyLevelLogin || localData.isFacilityLevelLogin || localData.isThriveLevelLogin) &&
        hospitalId
    ) {
        // Find the hospital entry matching the given hospitalId
        const hospitalData = localData.hospitalStaffPolicy.find((h: any) => h?.hospitalId === hospitalId);
        // If all levels are enabled, return all levels
        if (hospitalData.canPostLevel1Shift) {
            facilityLevels.push('Level 1');
        }
        if (hospitalData.canPostLevel2Shift) {
            facilityLevels.push('Level 2');
        }
        if (hospitalData.isLevel3Configured) {
            facilityLevels.push('Level 3');
        }
    } else if (localData.isAgencyLevelLogin) {
        facilityLevels = ['Level 3'];
    } else {
        facilityLevels = ['Level 1', 'Level 2', 'Level 3'];
    }

    return facilityLevels;
}
