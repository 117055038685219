import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateUtilService } from '../service/dateUtills.service';
import moment from 'moment-timezone';

@Pipe({
    name: 'localDateTime',
})
export class LocalDateTimePipe extends DatePipe implements PipeTransform {
    constructor(private dateUtilService: DateUtilService) {
        super('en-US');
    }

    override transform(value: any, format?: string, timezone?: string, locale?: string): any {
        if (!value) return value;

        // Append 'Z' to indicate UTC if not present
        let utcDateTime = value.endsWith('Z') ? value : `${value}Z`;

        if (format === 'ordinal') {
            if (value.length == 10) {
                utcDateTime = value;
            }
            // Transform the date using DatePipe
            const dateString = super.transform(utcDateTime);

            if (!dateString) return dateString;

            // Extract the day from the date
            const dayMatch = dateString.match(/\b\d{1,2}\b/);
            const day = dayMatch ? parseInt(dayMatch[0], 10) : 1;

            // Determine the ordinal suffix
            const ordinalSuffix = this.dateUtilService.getOrdinalSuffix(day);

            // Replace the day with the day + ordinal suffix in the date string
            return dateString.replace(/\b(\d{1,2})\b/, `${day}${ordinalSuffix}`);
        }
        if (format === 'atDateTime') {
            // Format the date part (e.g., 'Jun 12, 2024')
            const datePart = super.transform(utcDateTime, 'MMM d, y', timezone, locale);
            // Format the time part (e.g., '4:42 AM')
            const timePart = super.transform(utcDateTime, 'h:mm a', timezone, locale);

            // Combine the date and time parts
            return `${datePart} at ${timePart}`;
        } else if (format) {
            return super.transform(utcDateTime, format, timezone, locale);
        } else {
            return super.transform(utcDateTime);
        }
    }
}

@Pipe({
    name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(value: any, ...args: any[]): any {
        if (!value) return value; // Return the original value if it's null or undefined

        const formattedDate = this.datePipe.transform(value, 'MMM d, y'); // Format the date
        if (!formattedDate) return value; // In case date transformation fails, return original value

        const dateParts = formattedDate.split(' '); // Split the formatted date into parts
        if (dateParts.length < 3) return formattedDate; // Ensure the date is properly split into parts

        const day = parseInt(dateParts[1], 10); // Parse the day as an integer
        if (isNaN(day)) return formattedDate; // If day isn't a number, return the formatted date without ordinal

        const ordinalDay = this.getOrdinal(day); // Get the ordinal suffix for the day
        return `${dateParts[0]} ${ordinalDay}, ${dateParts[2]}`; // Construct the date string with the ordinal day
    }

    private getOrdinal(n: number): string {
        const s = ['th', 'st', 'nd', 'rd'];
        const v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }
}

@Pipe({
    name: 'roleBasedTimezone',
})
export class RoleBasedTimezonePipe extends DatePipe implements PipeTransform {
    constructor(private dateUtilService: DateUtilService) {
        super('en-US');
    }
    override transform(value: any, format?: string, timezone?: string, locale?: string): any {
        if (!value) return value;

        // Append 'Z' to indicate UTC if not present
        let utcDateTime = value.endsWith('Z') ? value : `${value}`;

        // Retrieve timezone from sessionStorage or use the provided timezone/local timezone as fallback
        let storedTimezone: any = sessionStorage.getItem('timezoneStandardName');
        // Map abbreviation to IANA timezone if necessary
        const activeTimezone = timezone
            ? timezone
            : storedTimezone != 'null' && storedTimezone != null
            ? storedTimezone
            : Intl.DateTimeFormat().resolvedOptions().timeZone;
        // Convert UTC time to the target timezone
        let convertedTime: any = moment(utcDateTime).utc(utcDateTime).tz(activeTimezone);
        convertedTime = convertedTime.format('YYYY-MM-DDTHH:mm:ss');

        if (format === 'ordinal') {
            if (value.length === 10) {
                utcDateTime = value;
            }
            const dateString = super.transform(convertedTime);
            if (!dateString) return dateString;

            const dayMatch = dateString.match(/\b\d{1,2}\b/);
            const day = dayMatch ? parseInt(dayMatch[0], 10) : 1;
            const ordinalSuffix = this.dateUtilService.getOrdinalSuffix(day);
            return dateString.replace(/\b(\d{1,2})\b/, `${day}${ordinalSuffix}`);
        }

        if (format === 'atDateTime') {
            const datePart = super.transform(convertedTime, 'MMM d, y');
            const timePart = super.transform(convertedTime, 'h:mm a');
            return `${datePart} at ${timePart}`;
        } else if (format) {
            return super.transform(convertedTime, format);
        } else {
            return super.transform(convertedTime);
        }
    }
}
