import { Pipe, PipeTransform } from '@angular/core';
import { TimeZoneService } from '../service/time-zone.service';

@Pipe({
    name: 'timezoneDifference',
})
export class TimezoneDifferencePipe implements PipeTransform {
    constructor(private timeZoneService: TimeZoneService) {}

    transform(facilityTimezone: any): string {
        const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // If facility timezone is provided
        if (facilityTimezone) {
            // Get the IANA timezone for the facility
            const facilityIana = this.timeZoneService.getIanaTimeZone(facilityTimezone);

            // If the system timezone is different from the facility's IANA timezone
            if (systemTimezone !== facilityIana) {
                // Get the short name of the facility timezone (e.g., 'EST', 'PST')
                const shortName: any = this.timeZoneService.getTimeZoneShortName(facilityTimezone);
                return `(${shortName})` || ''; // Return the short name or an empty string if not available
            }
        }
        return ''; // Return empty string if the timezones are the same or no facility timezone provided
    }
}

@Pipe({
    name: 'healthcareTimezoneDifference',
})
export class HealthcareTimezoneDifference implements PipeTransform {
    constructor(private timeZoneService: TimeZoneService) {}

    transform(timezone: any): string {
        const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Retrieve the timezone stored in session storage
        let facilityTimezone: any = sessionStorage.getItem('timezoneName'); // Replace 'userTimezone' with your actual session storage key
        // If the session timezone is null, return an empty string
        if (facilityTimezone != null && facilityTimezone != '' && !facilityTimezone) {
            return '';
        }

        // Check if facilityTimezone is valid and requires conversion
        const facilityIana = facilityTimezone
            ? this.timeZoneService.getIanaTimeZone(facilityTimezone) || facilityTimezone
            : null;

        // Use facilityIana only if it is a valid timezone
        if (facilityIana && systemTimezone !== facilityIana) {
            const shortName: any = this.timeZoneService.getTimeZoneShortName(facilityTimezone);
            return shortName ? `(${shortName})` : '';
        }

        // Return an empty string if the timezones are the same
        return '';
    }
}
