import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TimeZoneService {
    private timeZoneMapping: any = {
        'Aleutian Standard Time': { iana: 'Pacific/Honolulu', offset: 'UTC-10', shortName: 'AHST' },
        'Alaskan Standard Time': { iana: 'America/Anchorage', offset: 'UTC-9', shortName: 'AKST' },
        'Atlantic Standard Time': { iana: 'America/Halifax', offset: 'UTC-4', shortName: 'AST' },
        'Central Standard Time': { iana: 'America/Chicago', offset: 'UTC-6', shortName: 'CST' },
        'Eastern Standard Time': { iana: 'America/New_York', offset: 'UTC-5', shortName: 'EST' },
        'Hawaiian Standard Time': { iana: 'Pacific/Honolulu', offset: 'UTC-10', shortName: 'HST' },
        'Mountain Standard Time': { iana: 'America/Denver', offset: 'UTC-7', shortName: 'MST' },
        'Pacific Standard Time': { iana: 'America/Los_Angeles', offset: 'UTC-8', shortName: 'PST' },
        'Samoa Standard Time': { iana: 'Pacific/Apia', offset: 'UTC-11', shortName: 'SST' },
    };

    constructor() {}

    getIanaTimeZone(timeZoneName: string): string | null {
        return this.timeZoneMapping[timeZoneName]?.iana || null;
    }

    getUtcOffset(timeZoneName: string): string | null {
        return this.timeZoneMapping[timeZoneName]?.offset || null;
    }

    getTimeZoneInfo(timeZoneName: string): { iana: string; offset: string } | null {
        return this.timeZoneMapping[timeZoneName] || null;
    }

    getTimeZoneShortName(timeZoneName: string): { iana: string; offset: string } | null {
        return this.timeZoneMapping[timeZoneName]?.shortName || null;
    }
}
