import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTableRequestModelService } from './dataTableRequestModel.service';
import { ApiService } from './api.service';
import { API_REGION } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class RegionService {
    constructor(private apiService: ApiService, private dataTableRequestModelService: DataTableRequestModelService) {}

    getStateList(): Observable<any> {
        let req = {
            Skip: 0,
            Take: 10,
            SearchText: null,
            OrderBy: [['id asc']],
        };
        return this.dataTableRequestModelService.dataTableRequestService(API_REGION.GET_STATES_LIST, req);
    }

    stateSelectList(): Observable<any> {
        return this.apiService.get(API_REGION.GET_STATES_SELECTED_LIST);
    }

    getcountryList(): Observable<any> {
        let req = {
            Skip: 0,
            Take: 10,
            SearchText: null,
            OrderBy: [['id asc']],
        };
        return this.dataTableRequestModelService.dataTableRequestService(API_REGION.GET_COUNTRIES_LIST, req);
    }

    getCitiesByStateId(id: any): Observable<any> {
        return this.apiService.get(API_REGION.GET_CITIES + id);
    }
    addState(res: any): Observable<any> {
        return this.apiService.post(API_REGION.POST_STATES, res);
    }

    updateState(id: any, res: any): Observable<any> {
        return this.apiService.put(API_REGION.PUT_STATES + id, res);
    }

    deleteState(id: any): Observable<any> {
        return this.apiService.delete(API_REGION.DELETE_STATE, id);
    }

    addCity(res: any): Observable<any> {
        return this.apiService.post(API_REGION.POST_CITIES, res);
    }

    updateCity(id: any, res: any): Observable<any> {
        return this.apiService.put(API_REGION.PUT_CITIES + id, res);
    }

    deleteCity(id: any): Observable<any> {
        return this.apiService.delete(API_REGION.DELETE_CITIES, id);
    }

    getCityList(): Observable<any> {
        return this.apiService.get(API_REGION.GET_CITIES_SELECTED_LIST);
    }

    addCounty(res: any): Observable<any> {
        return this.apiService.post(API_REGION.POST_COUNTIES, res);
    }

    updateCounty(id: any, res: any): Observable<any> {
        return this.apiService.put(API_REGION.PUT_COUNTIES + id, res);
    }

    deleteCCounty(id: any): Observable<any> {
        return this.apiService.delete(API_REGION.DELETE_COUNTIES, id);
    }

    stateAcronymSelectList(): Observable<any> {
        return this.apiService.get(API_REGION.GET_STATES_ACRONYM_SELECTED_LIST);
    }

    getTimezoneList(): Observable<any> {
        return this.apiService.get(API_REGION.GET_TIMEZONE_LIST);
    }

    getState(id: any): Observable<any> {
        return this.apiService.get(API_REGION.GET_STATE + id);
    }
}
