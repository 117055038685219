import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appUnsavedChanges]'
})
export class UnsavedChangesDirective {
    @Input('appUnsavedChanges') form: FormGroup;
    @Input() hasFormChanged: () => boolean; // Receive the hasFormChanged method

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any): void {
      if (this.hasFormChanged && this.hasFormChanged() && this.form?.dirty) {
        $event.returnValue = true; // Display the default browser confirmation
      }
    }
}
