<div class="d-flex" [ngClass]="extraClass" *ngIf="type == 'checkbox'">
    <label [class.fw-bold]="!isLabelBold" class="form-label required" *ngIf="isRequired" for="formSwitch1">{{
        label
    }}</label>
    <label [class.fw-bold]="!isLabelBold" class="form-label" *ngIf="!isRequired" for="formSwitch1">{{ label }}</label>
    <div class="form-check form-switch mb-2 ms-2">
        <input
            type="checkbox"
            class="form-check-input"
            id="formSwitch1"
            [checked]="checkbBoxValue"
            (change)="chkOnChange($event)"
            [disabled]="isSelectDisable"
        />
    </div>
</div>

<div *ngIf="(type == 'text' || type == 'number') && !phoneMask">
    <label *ngIf="!isLabel" class="form-label">{{ label }}<span *ngIf="isRequired" class="required"></span></label>
    <input
        *ngIf="!placeholder"
        [class]="class"
        [name]="formField"
        [id]="formField"
        [disabled]="isDisabled"
        [value]="value"
        (input)="onChange($event)"
        autocomplete="off"
        [placeholder]="label"
        (blur)="touched()"
        [max]="max"
        [min]="min"
        [type]="type"
        (keydown)="preventArrowKeyChange($event)"
        (wheel)="preventScrollChange($event)"
    />
    <input
        *ngIf="placeholder"
        [class]="class"
        [name]="formField"
        [id]="formField"
        [disabled]="isDisabled"
        [value]="value"
        (input)="onChange($event)"
        autocomplete="off"
        [placeholder]="placeholder"
        (blur)="touched()"
        [max]="max"
        [min]="min"
        [type]="type"
        (keydown)="preventArrowKeyChange($event)"
        (wheel)="preventScrollChange($event)"
    />
</div>

<div *ngIf="type == 'text' && phoneMask">
    <label *ngIf="!isLabel" class="form-label">{{ label }}<span *ngIf="isRequired" class="required"></span></label>
    <input
        [class]="class"
        [name]="formField"
        [id]="formField"
        [disabled]="isDisabled"
        [value]="value"
        (input)="onChange($event)"
        autocomplete="off"
        [placeholder]="label"
        (blur)="touched()"
        [max]="max"
        [min]="min"
        [type]="type"
        appPhoneMask
    />
</div>

<ng-container
    *ngIf="isWhiteSpace && !isPattern"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'whitespace',
        message: label + ' is required',
        control: parentForm.controls[fieldName]
    }"
></ng-container>

<ng-container
    *ngIf="!isWhiteSpace && isText && parentForm?.get(fieldName)?.errors?.required"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'required',
        message: label + ' is required',
        control: parentForm.controls[fieldName]
    }"
></ng-container>
<ng-container
    *ngIf="
        !isWhiteSpace &&
        !isPattern &&
        isAlphabet &&
        max >= nameLength &&
        (parentForm?.get(fieldName)?.errors?.alphabeticWithSpaces ||
            parentForm?.get(fieldName)?.errors?.onlyCharactersWithDotAndSpaces ||
            parentForm?.get(fieldName)?.errors?.onlyNumber ||
            parentForm?.get(fieldName)?.errors?.onlyCharacters)
    "
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'pattern',
        message: 'Enter valid ' + label,
        control: parentForm.controls[fieldName]
    }"
></ng-container>
<ng-container
    *ngIf="
        !isWhiteSpace &&
        !isPattern &&
        isAlphabet &&
        max >= nameLength &&
        parentForm?.get(fieldName)?.errors?.onlyValidCharacters
    "
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'pattern',
        message: validationMessage,
        control: parentForm.controls[fieldName]
    }"
></ng-container>
<ng-container
    *ngIf="
        !isWhiteSpace && !isPattern && isAlphabet && parentForm?.get(fieldName)?.errors?.onlyNumberAndAfterDotTwoDigit
    "
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'pattern',
        message: 'Enter valid ' + label,
        control: parentForm.controls[fieldName]
    }"
></ng-container>
<!-- <ng-container
  *ngIf="isRequired"
  [ngTemplateOutlet]="formError"
  [ngTemplateOutletContext]="{
    validation: 'required',
    message: label + ' is required',
    control: parentForm.controls[fieldName]
  }"  
></ng-container> -->

<ng-container
    *ngIf="isEmail"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'email',
        message: 'Enter a valid email address',
        control: parentForm.controls[fieldName]
    }"
></ng-container>

<ng-container
    *ngIf="isPhoneNumber && isPattern"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'pattern',
        message: label + ' should be 10 digit',
        control: parentForm.controls[fieldName]
    }"
></ng-container>

<ng-container
    *ngIf="isWhiteSpace && isPattern && max >= nameLength"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'pattern',
        message: 'Enter vaild ' + label,
        control: parentForm.controls[fieldName]
    }"
></ng-container>

<ng-container
    *ngIf="max < nameLength && (!isWhiteSpace || isText) && !isRate"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'pattern',
        message: 'You can only enter a maximum of ' + max + (isNumber ? ' digit' : ' characters'),
        control: parentForm.controls[fieldName]
    }"
></ng-container>

<ng-container
    *ngIf="
        max < nameLength &&
        (!isWhiteSpace || isText) &&
        isRate &&
        !parentForm?.get(fieldName)?.errors?.onlyNumberAndAfterDotTwoDigit &&
        !parentForm?.get(fieldName)?.errors?.onlyNumbeMaxLength
    "
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'pattern',
        message: 'You can only enter a maximum of ' + max + (isNumber ? ' digit' : ' characters'),
        control: parentForm.controls[fieldName]
    }"
></ng-container>

<ng-container
    *ngIf="isRate && parentForm?.get(fieldName)?.errors?.onlyNumbeMaxLength"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'pattern',
        message: 'You can only enter a maximum of ' + rateMax + (isNumber ? ' digit' : ' characters'),
        control: parentForm.controls[fieldName]
    }"
></ng-container>

<ng-container
    *ngIf="space == true && nameLength > 0 && nameLength <= max && containsWhiteSpace(formField.value)"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'space',
        message: 'No space allow',
        control: formField
    }"
></ng-container>

<ng-container
    *ngIf="min > nameLength && isMinimum"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'pattern',
        message: 'Enter minimum ' + min + (isNumber ? ' digit' : ' characters'),
        control: parentForm.controls[fieldName]
    }"
></ng-container>

<ng-container
    *ngIf="
        isPattern &&
        min &&
        max &&
        !parentForm.controls[fieldName].errors?.minlength &&
        !parentForm.controls[fieldName].errors?.maxlength &&
        parentForm.controls[fieldName].errors?.pattern
    "
>
    <div class="input-error">Not a valid entry.</div>
</ng-container>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <!-- <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched || control.invalid)"> -->
    <ng-container *ngIf="control.invalid && control?.touched" class="error-message">
        <div class="fv-plugins-message-container">
            <div class="input-error">
                {{ message }}
            </div>
        </div>
    </ng-container>
</ng-template>
