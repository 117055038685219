import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AssetLevel, AssetsModel, MenuFor } from 'src/app/model/assets.model';
import { AssestsService } from 'src/app/service/assests.service';
import { noWhitespaceValidator, removeSpaces } from 'src/app/utill/validator';
import { IconModel } from 'src/app/model/icon.model';
import * as feather from 'feather-icons';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface FeatherIcon {
    toSvg: () => string;
}

@Component({
    selector: 'app-add-edit-assets-model',
    templateUrl: './add-edit-assets-model.component.html',
    styleUrls: ['./add-edit-assets-model.component.scss'],
})
export class AddEditAssetsModelComponent implements OnInit {
    @Input() editassetsModel = null;
    assetsForm: FormGroup;
    public assetsModel: any = new AssetsModel();
    public iconModel: any = new IconModel();
    canAddAssets = true;
    assetsList: any;
    saveDisable = false;
    assets: any[] = [];
    selectedParentAssetsId: any;
    isActive: boolean = true;
    isLabel: boolean = false;
    roleId: any;
    iconList: any[] = [];
    iconClass: any;
    isIconSelected: boolean = false;
    assetLevels: any[] = [];
    selectedAssetLevel: any;
    isItComingFromRealData: boolean = false;
    isParentAssetVisible = false;
    enum: any;
    menuFor: any[] = [];
    selectedMenuFor: any;
    constructor(
        private fb: FormBuilder,
        public modal: NgbActiveModal,
        public toastrService: ToastrService,
        private ngxSpinnerService: NgxSpinnerService,
        private assetsService: AssestsService,
        private sanitizer: DomSanitizer
    ) {
        this.enum = this.enumToObj(AssetLevel);
    }

    ngOnInit(): void {
        this.assetLevels = this.convertObjectToKeyValuePairs(this.enumToObj(AssetLevel));
        this.menuFor = this.convertObjectToKeyValuePairs(this.enumToObj(MenuFor));
        this.roleId = sessionStorage.getItem('roleId');
        // this.getIconClassList();
        if (!this.editassetsModel) {
            this.initForm();
        } else {
            this.canAddAssets = false;
            this.assetsModel = this.editassetsModel;
            this.selectedParentAssetsId = this.assetsModel.parentAssetsId;
            this.iconClass = this.assetsModel.iconClass;
            this.isActive = this.assetsModel.isActive;
            this.isLabel = this.assetsModel.isLabel;
            this.selectedAssetLevel = this.assetsModel.assetLevel;
            this.isParentAssetVisible = this.selectedAssetLevel != null && this.selectedAssetLevel > 1 ? true : false;
            this.selectedMenuFor = this.assetsModel.menuForType;
            this.isItComingFromRealData = this.assetsModel.isItComingFromRealData;
            this.getAssets();
            this.initForm();
        }
    }

    initForm() {
        this.assetsForm = this.fb.group(
            {
                id: [this.assetsModel?.id, Validators.compose([])],
                name: [
                    this.assetsModel?.name,
                    Validators.compose([
                        Validators.required,
                        noWhitespaceValidator,
                        removeSpaces,
                        Validators.minLength(1),
                    ]),
                ],
                displayText: [
                    this.assetsModel?.displayText,
                    Validators.compose([
                        Validators.required,
                        noWhitespaceValidator,
                        removeSpaces,
                        Validators.minLength(1),
                    ]),
                ],
                url: [
                    this.assetsModel?.url,
                    Validators.compose([
                        Validators.required,
                        noWhitespaceValidator,
                        removeSpaces,
                        Validators.minLength(1),
                    ]),
                ],
                parentAssetsId: [this.assetsModel?.parentAssetsId],
                menuFor: [this.assetsModel?.menuFor],
                isActive: [this.assetsModel?.isActive, Validators.compose([Validators.required])],
                isLabel: [this.assetsModel?.isLabel],
                position: [
                    this.assetsModel?.position,
                    Validators.compose([Validators.required, Validators.minLength(0), Validators.pattern('^[0-9]*$')]),
                ],
                descrption: [this.assetsModel?.descrption],
                iconClass: [this.assetsModel?.iconClass],
                assetLevel: [this.assetsModel?.assetLevel, Validators.compose([Validators.required])],
                isItComingFromRealData: [
                    this.assetsModel?.isItComingFromRealData,
                    Validators.compose([Validators.required]),
                ],
            },
            {}
        );
    }

    get f() {
        return this.assetsForm.controls;
    }
    submit() {
        if (this.assetsForm.invalid) {
            return;
        }

        const result: any = {};
        Object.keys(this.f).forEach((key) => {
            result[key] = this.f[key].value;
        });
        this.saveDisable = true;

        const assetsModel = new AssetsModel();
        assetsModel.setContactData(result);
        assetsModel.isActive = this.isActive;
        assetsModel.isLabel = this.isLabel;
        assetsModel.iconClass = this.iconClass;
        assetsModel.isItComingFromRealData = this.isItComingFromRealData;

        for (const key in MenuFor) {
            if (MenuFor[key as keyof typeof MenuFor] === this.selectedMenuFor) {
                assetsModel.menuFor = key;
            }
        }
        if (this.canAddAssets) {
            this.addAssets(assetsModel);
        } else {
            this.updateAssets(assetsModel);
        }
    }

    addAssets(assetsModel: any) {
        assetsModel.roleId = this.roleId !== null ? this.roleId : null;
        this.ngxSpinnerService.show();
        this.assetsService.addAssets(assetsModel).subscribe(
            (response: any) => {
                if (response.result) {
                    this.modal.close();
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err) => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(err.error.message);
            }
        );
    }

    updateAssets(assetsModel: any) {
        assetsModel.roleId = this.roleId !== null ? this.roleId : null;
        this.ngxSpinnerService.show();
        this.assetsService.updateAssets(assetsModel.id, assetsModel).subscribe(
            (response) => {
                if (response.result == true) {
                    this.toastrService.success(response.message);
                    this.modal.close();
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err) => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(err.error.message);
            }
        );
    }

    getAssets() {
        this.assetsService.getAssetsList(this.selectedAssetLevel).subscribe((response) => {
            this.assets = response.result;
        });
    }

    position() {
        let positionValue = this.assetsForm.controls.position.value;
        if (positionValue <= 0 || (positionValue && positionValue.toString().includes('.'))) {
            this.assetsForm.controls.position.setValue(null);
        }
    }
    onAssetsChange(event: any) {
        this.assetsForm.controls.parentAssetsId.setValue(event);
    }

    onMenuForChange(event: any) {
        if (event) {
            this.assetsForm.controls.menuFor.setValue(event);
            this.selectedMenuFor = event;
        } else {
            this.assetsForm.controls.menuFor.setValue(null);
            this.selectedMenuFor = null;
        }
    }

    isControlValid(controlName: string): boolean {
        const control = this.assetsForm.controls[controlName];
        return control?.valid && (control?.dirty || control?.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.assetsForm.controls[controlName];
        return control?.invalid && (control?.dirty || control?.touched);
    }

    dismissModel() {
        this.modal.dismiss();
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.assetsForm.controls[controlName].hasError(errorName);
    };

    onCheckboxChange(event: any) {
        this.isActive = event;
    }
    onLabelCheckboxChange(event: any) {
        this.isLabel = event;
    }

    onIconClassChange(data: any) {
        let id = data.value;
        this.assetsForm.controls.iconClass.setValue(id);
        this.iconClass = id;
        if (this.iconClass) {
            this.isIconSelected = true;
        }
    }

    getIconClassList() {
        this.iconList = this.iconModel.icons;
    }

    getFeatherIconPath(iconName: string): SafeHtml {
        const iconSvg = (feather.icons as { [key: string]: FeatherIcon })[iconName]?.toSvg() || '';
        return this.sanitizer.bypassSecurityTrustHtml(iconSvg);
    }

    onAssetLevelChange(event: any) {
        this.isParentAssetVisible = event != null && event > 1 ? true : false;
        this.assetsForm.controls.assetLevel.setValue(event);
        this.selectedAssetLevel = event;
        if (this.isParentAssetVisible) {
            this.getAssets();
        }
    }
    onIsItComingFromRealDataCheckboxChange(event: any) {
        this.isItComingFromRealData = event;
    }

    enumToObj(enumType: any): { [key: string]: number } {
        const enumObject: { [key: string]: number } = {};
        for (const key in enumType) {
            if (isNaN(Number(key))) {
                enumObject[key] = enumType[key];
            }
        }
        return enumObject;
    }

    convertObjectToKeyValuePairs(obj: any): { name: string; id: any }[] {
        return Object.entries(obj).map(([name, id]) => ({ name, id }));
    }
}
