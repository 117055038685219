import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RowClickHandlerService {
    constructor() {}

    handleRowClick(_row: any, event: any, maxIndex: any, onRowClick: (data: any) => void, excludedIndices?: any): void {
        if (!_row.length) return;

        const targetElement = event.row as HTMLElement;
        const parentRow = targetElement.closest('tr');

        if (parentRow) {
            // Add the 'cursor-pointer' class to the parentRow
            parentRow.classList.add('cursor-pointer');

            _row.off('click'); // Remove any previous click handler on _row

            _row.on('click', (e: any) => {
                // Add a single click handler for the entire row
                const clickedElement = e.target as HTMLElement;
                const tds = parentRow.querySelectorAll('td'); // Find all <td> elements in the row

                const clickedTdIndex = Array.from(tds).findIndex((td) => td.contains(clickedElement));
                // Use the custom condition to validate the click
                if (this.checkCondition(clickedTdIndex, maxIndex, excludedIndices)) {
                    // Call the valid click handler
                    onRowClick(event.data);
                }
            });
        }
    }

    checkCondition(index: number, maxIndex: any, excludedIndices: number[] = []): boolean {
        return index < maxIndex && !excludedIndices.includes(index);
    }
}
