import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

export const CUSTOMINPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ZipcodeComponent),
    multi: true,
};
export const CUSTOMINPUT_VALUE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => ZipcodeComponent),
    multi: true,
};

@Component({
    selector: 'app-zipcode',
    templateUrl: './zipcode.component.html',
    styleUrls: ['./zipcode.component.scss'],
    providers: [CUSTOMINPUT_VALUE_ACCESSOR],
})
export class ZipcodeComponent implements OnInit {
    public changed: (value: any) => void;
    public touched: () => void;
    public value: string;
    @Output() onChangeSelect: EventEmitter<any> = new EventEmitter();
    @Input() parentForm: FormGroup;
    @Input() fieldName: string;
    @Input() id: any;
    @Input() type: string;
    @Input() class: string = 'form-control';
    @Input() label: string;
    @Input() isDisabled: boolean = false;
    @Input() isRequired: boolean = false;
    @Input() max: number;
    @Input() min: number;
    @Input() placeholder: any;
    @Input() isLabel: boolean = false;
    @Input() isPattern: boolean = false;

    constructor() {}

    ngOnInit() {}

    get formField(): FormControl {
        return this.parentForm?.get(this.fieldName) as FormControl;
    }

    writeValue(value: string): void {
        if (value) {
            this.value = value.toString()?.trim();
        } else {
            this.value = value;
        }
    }

    registerOnChange(fn: any): void {
        this.changed = fn;
    }

    registerOnTouched(fn: any): void {
        this.touched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public chkOnChange(event: any) {
        this.onChangeSelect.emit(event.target.checked);
    }
    containsWhiteSpace(value: string): boolean {
        return /\s/.test(value);
    }

    // Format ZIP code to auto-insert a hyphen after 5 digits
    formatZipCode(event: Event) {
        const input = event.target as HTMLInputElement;
        let value = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

        if (value.length > 5) {
            value = `${value.slice(0, 5)}-${value.slice(5, 9)}`;
        }

        input.value = value;
        this.parentForm.controls[this.fieldName].setValue(value);
    }
}
