<label class="form-label {{ requiredClassName }}">{{ lableName }}</label>
<div class="input-group cursor-pointer" (click)="navigateToCalendarMonth(calendarMonth!, d, $event)">
    <input
        class="form-control disabled-input"
        placeholder="mm-dd-yyyy"
        [name]="fieldName"
        [id]="formField"
        [(ngModel)]="selectedFieldDate"
        ngbDatepicker
        #d="ngbDatepicker"
        (dateSelect)="onChange($event)"
        (keyup)="onChange($event)"
        autocomplete="off"
        [minDate]="displayPastDate ? null : todayDate"
        [maxDate]="displayPastDate && !isForFilter ? todayDate : null"
        [readonly]="true"
    />
    <button class="input-group-text" type="button">
        <i
            *ngIf="selectedFieldDate"
            class="feather icon-x icon-md text-muted cancel-icon"
            (click)="clearDate($event)"
        ></i>
        <i class="feather icon-calendar icon-md text-muted"></i>
    </button>
</div>
