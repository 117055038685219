// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    API: 'https://api.progtrak.com/stage/',
    WEB: 'https://stage.progtrak.com/',
    paging: {
        defaultPageSize: 10,
        take: 5000,
        skip: 0,
        lengthMenu: [10, 15, 20, 50, 100],
    },
    shiftPaging: {
        defaultPageSize: 5,
        lengthMenu: [5, 10, 15, 20, 50, 100],
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
