import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectionChange } from 'ngx-quill';

@Component({
    selector: 'app-html-editor',
    templateUrl: './html-editor.component.html',
    styleUrls: ['./html-editor.component.scss'],
})
export class HtmlEditorComponent implements OnInit {
    //editor config
    @Output() insertedData = new EventEmitter();
    @Input() insertedValue: any;
    @Input() isRequired: boolean = false;
    quillConfig = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                ['code-block'],
                //  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                //  [{ 'direction': 'rtl' }],                         // text direction

                //  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],

                [{ align: [] }],

                //  ['clean'],                                         // remove formatting button

                //  ['link'],
                ['link'],
            ],
        },
    };

    @Input() label: string;
    constructor() {}

    ngOnInit(): void {}

    onSelectionChanged = (event: any) => {
        this.insertedData.emit(event.editor.scrollingContainer.innerHTML);
    };
}
