<div class="modal-content">
    <div class="modal-header">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
            <ng-container> Cropper </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group cropper-form-group">
                <image-cropper
                    [imageChangedEvent]="imageUrl"
                    [containWithinAspectRatio]="containWithinAspectRatio"
                    [cropperMinWidth]="128"
                    [aspectRatio]="aspectRatio"
                    [roundCropper]="false"
                    [transform]="transform"
                    format="png"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"
                ></image-cropper>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary fw-semibold mr-2" (click)="dismissModel()">Cancel</button>
        <ng-container>
            <button type="submit" class="btn btn-orange btn-elevate" (click)="cropButton()">Crop</button>
        </ng-container>
    </div>
</div>
