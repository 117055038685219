import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appTimeFormat]', // The directive name to be used in HTML
})
export class TimeFormatDirective implements OnInit {
    @Input() type: string = ''; // To distinguish between 'fromTime' or 'toTime'

    constructor(private el: ElementRef, private renderer: Renderer2, private control: NgControl) {}

    ngOnInit() {
        // Initialize with the value of the input field if it already exists
        this.el.nativeElement.value = this.el.nativeElement.value ? this.formatTime(this.el.nativeElement.value) : '';
    }

    @HostListener('input', ['$event'])
    onInput(event: any): void {
        const inputValue = event.target.value;
        // Handle backspace separately: Do nothing if the inputType is 'deleteContentBackward'
        if (event.inputType === 'deleteContentBackward') {
            return; // If backspace is pressed, do nothing and return the current input value
        }
        let formattedValue = this.formatTime(inputValue);

        // Set the formatted value back to the input field
        event.target.value = formattedValue;
    }

    @HostListener('onBlur', ['$event'])
    onBlur(event: any): void {
        const formattedValue = this.formatTime(event.target.value);

        // Update the input value
        this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);

        // Update ngModel or FormControl
        if (this.control && formattedValue) {
            this.control.control?.setValue(formattedValue, { emitEvent: true });
        }
    }

    formatTime(input: string): string {
        let validInput = input.toUpperCase().replace(/[^0-9:AP]/g, ''); // Valid input characters: numbers, colon, A, P

        // Handle max input length
        if (validInput.length > 8) {
            validInput = validInput.substring(0, 8); // Restrict to 'HH:MM AM/PM'
        }

        // Remove alphabets when length is 5
        if (validInput.length === 5) {
            validInput = validInput.replace(/[A-Za-z]/g, ''); // Remove any alphabets
        }

        // Automatically insert ':' after 2 digits
        if (validInput.length === 2 && !validInput.includes(':')) {
            validInput = validInput + ':'; // Insert colon after 2 digits
        }

        // Automatically append a space after valid HH:MM
        if (validInput.length === 5 && !validInput.includes(' ')) {
            validInput = validInput + ' '; // Append space after 'HH:MM'
        }

        // Automatically add AM/PM when user enters it (6th character)
        if (validInput.length === 6 && (validInput.endsWith('A') || validInput.endsWith('P'))) {
            const lastChar = validInput[validInput.length - 1]; // Get the last character
            validInput = validInput.slice(0, -1).trim() + (lastChar === 'A' ? ' AM' : ' PM'); // Append AM/PM after the time
        }

        // Handle reset if ':' is misplaced
        if (validInput.length < 3 && validInput.includes(':')) {
            validInput = ''; // Reset value if ':' is misplaced
        }

        // Time and period validation
        const [timePart, period] = validInput.split(' '); // Split into time and AM/PM
        const [hours, minutes] = (timePart || '').split(':').map(Number);

        // Reset if the time is invalid
        if (hours > 12 || minutes > 59) {
            validInput = ''; // Invalid time
        }

        // Reset if invalid period is entered
        if (period && period !== 'AM' && period !== 'PM') {
            validInput = timePart + ' '; // Invalid period (AM/PM)
        }

        // Handle input greater than 6, and ensure it's 'HH:MM'
        if (validInput.length > 6 && !validInput.includes('AM') && !validInput.includes('PM')) {
            validInput = validInput.substring(0, 5); // Keep only 'HH:MM'
        }

        return validInput; // Return the modified valid input
    }
}
