import { Injectable } from '@angular/core';
import { FilterModel } from '../model/searchJson.model';

@Injectable({
    providedIn: 'root',
})
export class FilterService {
    private storageKey = 'filterStates';
    private filterStates: { [key: string]: FilterModel[] } = {};

    constructor() {
        this.loadFromStorage();
    }

    private loadFromStorage() {
        const savedFilters = localStorage.getItem(this.storageKey);
        if (savedFilters) {
            this.filterStates = JSON.parse(savedFilters);
        }
    }

    private saveToStorage() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.filterStates));
    }

    setFilterState(route: string, state: any) {
        this.filterStates[route] = state;
        this.saveToStorage();
    }

    getFilterState(route: string) {
        return this.filterStates[route] || {};
    }

    clearFilterState(route: string) {
        delete this.filterStates[route];
        this.saveToStorage();
    }
}