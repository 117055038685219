export class CityModel {
    constructor() {
        this.id = 0;
        this.name = null;
        this.stateId = null;
        this.isActive = true;
    }

    public id: 0;
    public name: any;
    public stateId: any;
    isActive: boolean;

    setCityData(city: any) {
        this.id = city.id;
        this.name = city.name;
        this.stateId = city.stateId;
        this.isActive = city.isActive;
    }
}
