<div class="modal-header">
    <div class="modal-title h4">Assets Delete</div>
</div>
<div class="modal-body">
    <ng-container>
        <span>Are you sure you want to delete this Assets?</span>
    </ng-container>
</div>

<div class="modal-footer">
    <div>
        <button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">Cancel</button>&nbsp;
        <button type="button" class="btn btn-orange btn-elevate" (click)="deleteAssets()">Delete</button>
    </div>
</div>
