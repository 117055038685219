import {
    AfterViewInit,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    forwardRef,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidatorFn,
} from '@angular/forms';

export const CUSTOMINPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputboxComponent),
    multi: true,
};
export const CUSTOMINPUT_VALUE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => InputboxComponent),
    multi: true,
};
@Component({
    selector: 'app-inputbox',
    templateUrl: './inputbox.component.html',
    styleUrls: ['./inputbox.component.scss'],
    providers: [CUSTOMINPUT_VALUE_ACCESSOR],
})
export class InputboxComponent implements OnInit {
    public changed: (value: any) => void;
    public touched: () => void;
    public value: string;
    @Input() parentForm: FormGroup;
    @Input() fieldName: string;
    @Input() id: any;
    @Input() type: string;
    @Input() class: string = 'mt-1 form-control';
    @Input() label: string;
    @Input() isDisabled: boolean = false;
    @Input() isWhiteSpace: boolean = false;
    @Input() isRequired: boolean = false;
    @Input() isEmail: boolean = false;
    @Input() isText: boolean = false;
    @Input() isPattern: boolean = false;
    @Input() isPhoneNumber: boolean = false;
    @Input() isInputCheckbox: boolean = false;
    @Input() isSelectDisable: boolean = false;
    @Input() isLabel: boolean = false;
    @Input() isAlphabet: boolean = false;
    @Input() phoneMask: boolean;
    @Input() extraClass: string;
    @Input() space: boolean = false;
    @Input() max: number;
    @Input() min: number;
    @Input() checkbBoxValue: boolean;
    @Input() isMinimum: boolean = false;
    @Input() isNumber: boolean = false;
    @Input() isLabelBold: boolean = true;
    @Output() onChangeSelect: EventEmitter<any> = new EventEmitter();
    nameLength: number;
    @Input() placeholder: any;
    @Input() isRate: boolean = false;
    @Input() rateMax: any;
    validationMessage: any;

    constructor() {}
    ngOnInit(): void {
        this.validationMessage = `Only letters(A-Z, a-z), space, period (.), hyphen (-), and apostrophe (') are allowed.`;
    }

    get formField(): FormControl {
        return this.parentForm?.get(this.fieldName) as FormControl;
    }

    writeValue(value: string): void {
        if (value) {
            this.value = value.toString()?.trim();
        } else {
            this.value = value;
        }
    }

    public onChange(event: Event) {
        const value: string = (<HTMLInputElement>event?.target).value;
        this.changed(value);
        this.nameLength = value.length;
    }

    registerOnChange(fn: any): void {
        this.changed = fn;
    }

    registerOnTouched(fn: any): void {
        this.touched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public chkOnChange(event: any) {
        this.onChangeSelect.emit(event.target.checked);
    }
    containsWhiteSpace(value: string): boolean {
        return /\s/.test(value);
    }

    preventArrowKeyChange(event: KeyboardEvent): void {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault(); // Disable up/down arrow key behavior
        }
    }

    preventScrollChange(event: WheelEvent): void {
        if ((event.target as HTMLInputElement).type === 'number') {
            event.preventDefault(); // Disable mouse scroll behavior
        }
    }
}
