<div class="modal-header border-primary">
    <h5 class="text-primary">Unsaved Changes</h5>
</div>
<div class="modal-body p-5">
    <div class="text-center">
        <h6>
            {{ message }}
        </h6>
    </div>
</div>

<div class="mb-4 mt-3 d-flex justify-content-md-evenly">
    <div>
        <button type="button" class="btn btn-light btn-elevate mr-1 btn-lg px-5 btn-border" (click)="callBack(false)">
            Cancel
        </button>
        <button type="button" class="btn btn-light btn-orange mr-1 btn-lg px-5 ms-4" (click)="callBack(true)">
            Confirm
        </button>
    </div>
</div>
