import { Component, OnInit } from '@angular/core';
import { FilterService } from './service/filter.service';
import { NavigationEnd, Router } from '@angular/router';

const FILTER_PERSISTENCE_MAP: { [key: string]: string } = {
    '/shift/calendar' : '/shift/view-shift',
    '/shift/shift-list': '/shift/view-shift',
    '/shift/bulk-shift-list': '/shift/view-shift',
    '/all-jobs':'/all-jobs/view-job',
    // Add more list-detail mappings here...
};

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'thrive-nurse';
    private lastRoute: string = '';

    constructor(private router: Router, private filterService: FilterService) { }

    ngOnInit(): void {
        const storedLastRoute = localStorage.getItem('lastRoute');
        if (storedLastRoute) {
            this.lastRoute = storedLastRoute;
        }

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const currentRoute = event.url.split('?')[0];
                const lastRouteBase = this.lastRoute.split('?')[0];
                localStorage.setItem('lastRoute', currentRoute);
                // Check if the current or last route belongs to a list-detail mapping
                const listPage = this.getListPage(lastRouteBase);
                const detailPagePrefix = listPage ? FILTER_PERSISTENCE_MAP[listPage] : null;

                if (listPage) {
                    if (currentRoute === listPage || (detailPagePrefix && currentRoute.startsWith(detailPagePrefix))) {
                        // Do nothing, keep the filter
                    } else {
                        // Clear filter when moving to another section
                        this.filterService.clearFilterState(listPage);
                    }
                }
                this.lastRoute = currentRoute;
            }
        });

    }

    private getListPage(route: string): string | null {
        return Object.keys(FILTER_PERSISTENCE_MAP).find((listPage) =>
            route.startsWith(listPage) || route.startsWith(FILTER_PERSISTENCE_MAP[listPage])
        ) || null;
    }
}