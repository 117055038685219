<nav aria-label="breadcrumb" class="nav-item">
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item">
            <a routerLink="/{{ currentUrl }}" (click)="goBack()" *ngIf="!id">
                {{ transform(currentUrl) }}
            </a>
            <a routerLink="/{{ currentUrl }}" *ngIf="id" [queryParams]="{ id: id }">
                {{ transform(currentUrl) }}
            </a>
        </li>
        <!-- <li *ngIf="type == 'job'" class="breadcrumb-item">
            <a routerLink="/job-opening/view-applicants" [queryParams]="{ id: id }">
                {{ transform(currentUrl) }}
            </a>
        </li> -->
        <!-- <li
            class="breadcrumb-item active"
            aria-current="page"
            *ngIf="
                addEditViewUrl == 'manage-company' ||
                addEditViewUrl == 'manage-unit' ||
                addEditViewUrl == 'manage-facility' ||
                addEditViewUrl == 'add-credential-template' ||
                addEditViewUrl == 'add-job-template' ||
                addEditViewUrl == 'manage-shift' ||
                addEditViewUrl == 'manage-job' ||
                addEditViewUrl == 'create-job' ||
                addEditViewUrl == 'add-rate-card'
            "
        >
            {{ 'Manage ' + transform(currentUrl) }}
        </li> -->
        <!-- <li
            [ngClass]="addEditViewUrl == 'facility-shift-time' ? 'breadcrumb-item active1' : 'breadcrumb-item active'"
            aria-current="page"
            *ngIf="addEditViewUrl == 'manage-unit-time' || type == 'shiftTime'"
        >
            <a routerLink="/facility/manage-unit-time" [queryParams]="{ facilityId: id }">
                {{ 'Unit' }}
            </a>
        </li> -->
        <!-- <li class="breadcrumb-item active" aria-current="page" *ngIf="addEditViewUrl == 'facility-shift-time'">
            {{ transform(addEditViewUrl) }}
        </li> -->
        <li class="breadcrumb-item active" aria-current="page" *ngIf="addEditViewUrl">
            {{ 'View Details' }}
        </li>
    </ol>
</nav>

<!-- <nav aria-label="breadcrumb" *ngIf="type == 'unit'" class="nav-item">
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/facility" [queryParams]="{ id: id, type: type }" *ngIf="type == 'unit'">
                {{ 'Facility' }}
            </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" *ngIf="addEditViewUrl == 'bulk-upload'">
            {{ 'Bulk Upload' }}
        </li>
    </ol>
</nav>

<nav aria-label="breadcrumb" *ngIf="type == 'list' || type == 'grid'" class="nav-item">
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item">
            <a routerLink="/staff-grid">
                {{ 'Staff Grid' }}
            </a>
        </li>
        <li
            class="breadcrumb-item active"
            aria-current="page"
            *ngIf="addEditViewUrl == 'view-staff' || addEditViewUrl == 'staff-profile'"
        >
            {{ 'View Details' }}
        </li>
    </ol>
</nav> -->

<!-- <nav aria-label="breadcrumb"  *ngIf="type == 'grid' " class="nav-item">
  <ol class="breadcrumb breadcrumb-arrow">
    <li class="breadcrumb-item" aria-current="page">
      <a routerLink="/staff-list">
          {{ 'Staff List' }}
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page"  *ngIf="addEditViewUrl == 'view-staff' || addEditViewUrl == 'staff-profile'">
      {{ 'View Details' }}
    </li>
  </ol>
</nav> -->

<!-- <nav aria-label="breadcrumb" *ngIf="type == 'opening'" class="nav-item">
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item">
            <a routerLink="/job-opening">
                {{ 'View Applicants' }}
            </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" *ngIf="addEditViewUrl == 'view-staff'">
            {{ 'View Details' }}
        </li>
    </ol>
</nav> -->

<!-- <nav aria-label="breadcrumb" *ngIf="ishospitalprofile" class="nav-item">
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/{{ currentUrl }}/facility-view" [queryParams]="{ id: id, type: type1, enumType: enumType }">
                {{ 'View Details' }}
            </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" *ngIf="addEditViewUrl == 'manage-facility'">
            {{ 'Manage ' + currentUrl | titlecase }}
        </li>
    </ol>
</nav> -->

<!-- <nav aria-label="breadcrumb" *ngIf="isCompanyProfile" class="nav-item">
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/{{ currentUrl }}/company-view" [queryParams]="{ id: id, type: type1, enumType: enumType }">
                {{ 'View Details' }}
            </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" *ngIf="addEditViewUrl == 'manage-company'">
            {{ 'Manage ' + currentUrl | titlecase }}
        </li>
    </ol>
</nav> -->

<!-- <nav aria-label="breadcrumb" *ngIf="isThrive" class="nav-item">
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item active" aria-current="page">
            <a>
                {{ 'View Details' }}
            </a>
        </li>
    </ol>
</nav>
<nav aria-label="breadcrumb" *ngIf="isThriveEditProfile" class="nav-item">
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item active" aria-current="page">
            <a routerLink="/thrive-user-profile" [queryParams]="{ type: type1 }">
                {{ 'View Details' }}
            </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" *ngIf="currentUrl == 'edit-profile'">
            {{ 'Manage Profile' }}
        </li>
    </ol>
</nav>

<nav
    aria-label="breadcrumb"
    *ngIf="
        !iscurrentUrl &&
        !ishospitalprofile &&
        !isCompanyProfile &&
        !isThrive &&
        !isThriveEditProfile &&
        !isBulkUploadHospital &&
        currentUrl != 'job-opening' &&
        currentUrl != 'applicant-referance' &&
        currentUrl != 'time-sheet-dispute-reason'
    "
    class="nav-item"
>
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item active">
            <a routerLink="/{{ currentUrl }}">
                {{ transform(currentUrl) }}
            </a>
        </li>
    </ol>
</nav>

<nav
    aria-label="breadcrumb"
    *ngIf="iscurrentUrl && ishospitalprofile && isCompanyProfile && isThrive && isThriveEditProfile && !isState"
    class="nav-item"
>
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item">
            <a>
                {{ 'Location' }}
            </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            {{ currentUrl | titlecase }}
        </li>
    </ol>
</nav>

<nav
    aria-label="breadcrumb"
    *ngIf="
        currentUrl == 'job-opening' ||
        type == 'job' ||
        currentUrl == 'applicant-reference' ||
        type == 'staff' ||
        type == 'staffId'
    "
    class="nav-item"
>
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item" *ngIf="type != 'job'">
            <a routerLink="/job-creation">
                {{ 'Job Creation' }}
            </a>
        </li>
        <li class="breadcrumb-item" *ngIf="type == 'job'">
            <a routerLink="/job-opening/view-applicants" [queryParams]="{ id: id, jobId: jobId }">
                {{ 'Job Creation' }}
            </a>
        </li>
        <li class="breadcrumb-item active" *ngIf="type == 'job'">
            <a>
                {{ 'View Job' }}
            </a>
        </li>
        <li
            [ngClass]="
                this.addEditViewUrl == 'view-jobOpening' ||
                this.addEditViewUrl == 'manage-job' ||
                this.addEditViewUrl == 'view-applicants' ||
                this.addEditViewUrl == 'applicant-detail' ||
                this.addEditViewUrl == 'view-jobOpening'
                    ? 'breadcrumb-item active1'
                    : 'breadcrumb-item active'
            "
            aria-current="page"
            *ngIf="type != 'job'"
        >
            <a routerLink="/job-opening" [queryParams]="{ jobId: jobId }">
                {{ 'Job Opening' }}
            </a>
        </li>
        <li *ngIf="addEditViewUrl == 'view-jobOpening'" class="breadcrumb-item active" aria-current="page">
            {{ 'View Job Opening' }}
        </li>
        <li *ngIf="addEditViewUrl == 'manage-job'" class="breadcrumb-item active" aria-current="page">
            {{ 'Manage Job Opening' }}
        </li>
        <li
            [ngClass]="addEditViewUrl == 'applicant-detail' ? 'breadcrumb-item active1' : 'breadcrumb-item active'"
            *ngIf="
                addEditViewUrl == 'view-applicants' ||
                type == 'staff' ||
                addEditViewUrl == 'applicant-detail' ||
                type == 'staffId' ||
                currentUrl == 'applicant-reference'
            "
            aria-current="page"
        >
            <span
                class="cursor-pointer"
                routerLink="/job-opening/view-applicants"
                [queryParams]="{ id: id, jobId: jobId }"
            >
                {{ 'List of Applicants' }}
            </span>
        </li>
        <li *ngIf="type == 'staffId'" aria-current="page">
            <span
                class="cursor-pointer"
                routerLink="/job-opening/applicant-detail"
                [queryParams]="{ id: id, data: data, jobId: jobId }"
            >
                {{ 'Applicant Detail' }}
            </span>
        </li>
        <li class="breadcrumb-item active" *ngIf="addEditViewUrl == 'applicant-detail'">
            {{ transform(addEditViewUrl) }}
        </li>
        <li [ngClass]="'breadcrumb-item active'" *ngIf="type == 'staff'">
            {{ 'View Details' }}
        </li>
        <li [ngClass]="'breadcrumb-item active'" *ngIf="type == 'staffId'">
            {{ 'View Details' }}
        </li>
        <li [ngClass]="'breadcrumb-item active'" aria-current="page" *ngIf="currentUrl == 'applicant-reference'">
            <span
                class="cursor-pointer"
                routerLink="/applicant-reference"
                [queryParams]="{ applicantId: applicantId, jobOpeningId: jobOpeningId, id: id, jobId: jobId }"
            >
                {{ transform(currentUrl) }}
            </span>
        </li>
        <li aria-current="page" *ngIf="addEditViewUrl == 'view-feedback'">
            {{ transform(addEditViewUrl) }}
        </li>
    </ol>
</nav>

<nav aria-label="breadcrumb" *ngIf="currentUrl == 'time-sheet-dispute-reason'" class="nav-item">
    <ol class="breadcrumb breadcrumb-arrow">
        <li class="breadcrumb-item">
            <a>
                {{ 'Dispute Reason' }}
            </a>
        </li>
    </ol>
</nav> -->
