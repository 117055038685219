<label class="form-label required" *ngIf="isRequired" for="formSwitch1">{{ label }}</label>
<label class="form-label" *ngIf="!isRequired" for="formSwitch1">{{ label }}</label>
<quill-editor
    placeholder="Enter {{ label }}"
    [modules]="quillConfig"
    [(ngModel)]="insertedValue"
    (onEditorChanged)="onSelectionChanged($event)"
    class="editor-content"
>
</quill-editor>
