<div class="row align-items-center">
    <div class="col-lg-12 col-md-12">
        <div class="d-flex justify-content-md-end flex-wrap justify-content-between">
            <div class="filter-create-btn my-2 mb-md-0">
                <app-titlebar title="Assets" (create)="create()"> </app-titlebar>
            </div>
        </div>
    </div>
</div>
<div class="position-responsive">
    <app-datatable
        [configDataTable]="dataTableModel"
        (rowClickHandler)="rowCallback($event)"
        [isLoadData]="dataTableModel.isLoadData"
    >
    </app-datatable>
</div>
