import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { LayoutModule } from 'src/app/views/layout/layout.module';
import { AssetsComponent } from './assets.component';
import { AddEditAssetsModelComponent } from './add-edit-assets-model/add-edit-assets-model.component';
import { DeleteAssetsModelComponent } from './delete-assets-model/delete-assets-model.component';
import { AssetsRoutingModule } from './assets-routing.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [AssetsComponent, AddEditAssetsModelComponent, DeleteAssetsModelComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        LayoutModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        AssetsRoutingModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AssetsModule {}
