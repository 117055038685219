import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateAgoPipe',
    pure: true,
})
export class DateAgoPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            const currentTime = new Date();
            const notificationTime = new Date(value);
            const userTimezoneOffset = currentTime.getTimezoneOffset() * 60 * 1000;
            const adjustedTime = new Date(notificationTime.getTime() - userTimezoneOffset);
            const diffInSeconds = Math.floor((currentTime.getTime() - adjustedTime.getTime()) / 1000);

            if (diffInSeconds < 30) {
                return 'Just Now';
            }

            const intervals: any = {
                year: 31536000,
                month: 2592000,
                week: 604800,
                day: 86400,
                hour: 3600,
                minute: 60,
                second: 1,
            };

            for (const i in intervals) {
                const counter = Math.floor(diffInSeconds / intervals[i]);
                if (counter > 0) {
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago'; // singular (1 day ago)
                    } else {
                        return counter + ' ' + i + 's ago'; // plural (2 days ago)
                    }
                }
            }
            // If the difference is greater than a year
            const years = Math.floor(diffInSeconds / 31536000);
            return years + ' year' + (years > 1 ? 's' : '') + ' ago';
        }
        return value;
    }
}
