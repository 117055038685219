import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { setOptions } from '@mobiscroll/angular';

setOptions({
    key: '45c04c16-4410-4ff1-af16-42323e5013d2',
});

  // Set records per page dynamically
function setRecordsPerPage(screenWidth: number): number {
    if (screenWidth < 600) {
     return 5; // Small screens
    } else if (screenWidth >= 600 && screenWidth < 1024) {
        return 10; // Medium screens
    } else if (screenWidth >= 1024 && screenWidth < 1921) {
        return 15; // Medium screens
    } else {
        return 20; // Large screens
    }
}

// Update the environment variable before Angular bootstrap
environment.paging.defaultPageSize = setRecordsPerPage(window.innerWidth);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
