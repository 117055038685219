import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AssestsService } from 'src/app/service/assests.service';

@Component({
    selector: 'app-delete-assets-model',
    templateUrl: './delete-assets-model.component.html',
    styleUrls: ['./delete-assets-model.component.scss'],
})
export class DeleteAssetsModelComponent implements OnInit {
    @Input() id: any;
    subscriptions: Subscription[] = [];
    constructor(
        public modal: NgbActiveModal,
        private assestsService: AssestsService,
        private toastrService: ToastrService,
        private ngxSpinnerService: NgxSpinnerService
    ) {}

    ngOnInit(): void {}

    deleteAssets() {
        this.ngxSpinnerService.show();
        this.assestsService.deleteAssets(this.id).subscribe(
            (Response) => {
                this.modal.close();
                this.toastrService.success(Response.message);
                this.ngxSpinnerService.hide();
            },
            (err) => {
                this.toastrService.error(err.error.message);
                this.ngxSpinnerService.hide();
            }
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
    }
}
