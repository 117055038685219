<div class="modal-body">
    <ng-container>
        <span>{{ message }}</span>
    </ng-container>
</div>

<div class="modal-footer">
    <div>
        <button type="button" class="btn btn-light btn-elevate mr-1 btn-border" (click)="modal.dismiss()">No</button
        >&nbsp;
        <button type="button" class="btn btn-primary btn-elevate" (click)="callBack()">Yes</button>
    </div>
</div>
