import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';
import { DataTableRequestModelService } from './dataTableRequestModel.service';
import { ApiService } from './api.service';
import { API_CHAT } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    userDetail: any = sessionStorage.getItem('userDetail');
    userInfo = JSON.parse(this.userDetail);
    token = this.userInfo.token;
    private retryCount = 0;
    private maxRetries = 5;
    private retryDelay = 5000;

    public connection: signalR.HubConnection;

    @Output() messageReceived = new EventEmitter<any>();

    constructor(private apiService: ApiService, private dataTableRequestModelService: DataTableRequestModelService) {
        this.initializeConnection();
    }

    initializeConnection(): void {
        if (this.connection && this.connection.state !== signalR.HubConnectionState.Disconnected) {
            return;
        }

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.API}chatHub`, {
                transport: signalR.HttpTransportType.WebSockets,
                skipNegotiation: true,
                accessTokenFactory: () => this.token,
            })
            .build();

        this.startConnection();
    }

    async startConnection(): Promise<void> {
        if (this.connection.state !== signalR.HubConnectionState.Disconnected) {
            return;
        }

        try {
            await this.connection.start();
            this.retryCount = 0;
            this.registerOnServerEvents(); // Register server events after connection
        } catch (err) {
            console.error(
                `Error starting SignalR connection. Attempt ${this.retryCount + 1} of ${this.maxRetries}:`,
                err
            );
            if (this.retryCount < this.maxRetries) {
                this.retryCount++;
                setTimeout(() => this.startConnection(), this.retryDelay);
            } else {
                console.error('Max retries reached. Connection could not be established.');
            }
        }
    }

    async joinChat(url: any): Promise<void> {
        try {
            if (this.connection.state !== signalR.HubConnectionState.Connected) {
                await this.startConnection();
            }

            await this.connection.invoke('Join', url);
        } catch (err) {
            console.error('Error joining chat:', err);
        }
    }

    async leaveChat(userId: any): Promise<void> {
        return this.connection.invoke('LeaveChat', userId);
    }

    registerOnServerEvents(): void {
        if (this.connection.state === signalR.HubConnectionState.Connected) {
            this.connection.on('newMessage', (data) => {
                this.messageReceived.emit(data);
            });
        } else {
            console.error('Cannot register events. SignalR connection is not in the Connected state.');
        }
    }

    getChatList(req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForChat(API_CHAT.GET_CHAT_LIST, req);
    }

    getChatDetails(req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForChatDetails(API_CHAT.GET_CHAT_DETAILS, req);
    }

    postMessage(obj: any): Observable<any> {
        if (this.connection.state !== signalR.HubConnectionState.Connected) {
            console.error('Connection is not in the Connected state. State:', this.connection.state);
            return throwError(new Error('SignalR connection is not established.'));
        }

        return this.apiService.post(API_CHAT.POST_CHAT, obj).pipe(
            switchMap((response: any) => {
                return of(response);
            }),
            catchError((error: any) => {
                console.error('Error sending message:', error);
                return throwError(error);
            })
        );
    }

    upateChatStatus(chatGroupId: any) {
        return this.apiService.put(API_CHAT.UPDATE_CHAT + '?chatGroupId=' + chatGroupId + '&callingFrom=' + 'web', {});
    }
}
