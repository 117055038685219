<div class="modal-content fixwidth chat-main-model cat-conversation-top">
    <div class="modal-header">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
            <ng-container>
                <i class="fas fa-chevron-left mr-4 cursor-pointer" (click)="back()"> </i>
                <span class="m-2">Conversation</span>
            </ng-container>
        </div>
    </div>

    <div class="document-list chat-log-list">
        <div class="all-job-box card p-3 rounded-0 shadow-none h-100 d-flex flex-column">
            <div class="job-top-box d-flex align-datas-center">
                <figure class="me-3">
                    <img
                        *ngIf="data?.hospitalProfileUrl"
                        [src]="data.hospitalProfileUrl"
                        alt="Center image"
                        class="wd-60 ht-60"
                    />
                    <img *ngIf="!data?.hospitalProfileUrl" src="assets/images/others/company.png" class="wd-60 ht-60" />
                </figure>
                <div class="job-top-info flex-grow-1">
                    <h5 class="mb-1">
                        {{ data?.hospitalName }}
                    </h5>
                    <p class="mb-1" *ngIf="data?.typeName != 'General Chat'">
                        {{ data?.typeName }} : {{ data?.licenseTypeAcronym }} - {{ data.specialityName }}
                    </p>
                    <p class="mb-1" *ngIf="data?.shift">{{ data.shift }} | {{ data?.date }} - {{ data?.time }}</p>
                    <div class="d-flex align-datas-center">
                        <figure class="me-2">
                            <img
                                *ngIf="data?.clinicianProfileUrl"
                                [src]="data.clinicianProfileUrl"
                                alt="User image"
                                class="wd-40 ht-40 rounded-circle"
                            />

                            <div *ngIf="!data?.clinicianProfileUrl">
                                <span
                                    class="d-flex justify-content-center wd-40 ht-40 align-items-center bg-primary fw-bolder rounded-circle text-white"
                                >
                                    <p>
                                        {{ data?.clinicianName?.split(' ')?.[0]?.charAt(0)


                                        }}{{ data?.clinicianName?.split(' ')?.[1]?.charAt(0) }}
                                    </p>
                                </span>
                            </div>
                        </figure>
                        <div>
                            <p class="mb-0">Chat with {{ data?.clinicianName }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div #scrollable class="chat-log job-profile-card-list" (scroll)="onScroll()">
        <div *ngFor="let item of chatModel; let i = index">
            <div class="chat-log-top">
                <div class="msger-chat">
                    <span class="msger-timeline" *ngIf="item.isNewDay">{{ item.date }}</span>
                    <div class="msger-chat-box msger-chat-left" *ngIf="item._userId != userId">
                        <div class="msger-img">
                            <figure *ngIf="item.avatar">
                                <img [src]="item.avatar" alt="" />
                            </figure>
                            <div *ngIf="!item.avatar" class="rounded-circle">
                                <span
                                    class="d-flex justify-content-center align-items-center bg-primary fw-bolder msger-badge"
                                >
                                    <p>
                                        {{ item?.name?.split(' ')?.[0]?.charAt(0)







                                        }}{{ item?.name?.split(' ')?.[1]?.charAt(0) }}
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div class="msger-msg-box">
                            <div class="msger-msg">
                                <p style="word-wrap: break-word">{{ item.text }}</p>
                                <div class="d-flex justify-content-end">
                                    <p class="text-muted">~ {{ item?.name }}</p>
                                </div>
                            </div>
                            <span class="msg-time">{{ item.time | roleBasedTimezone : 'shortTime' }}</span>
                        </div>
                    </div>
                    <div class="msger-chat-box msger-chat-right" *ngIf="item._userId == userId">
                        <div class="msger-msg-box">
                            <div class="msger-msg">
                                <p style="word-wrap: break-word">{{ item.text }}</p>
                            </div>
                            <span class="msg-time">{{ item.time | roleBasedTimezone : 'shortTime' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slide-chat-log-bottom">
        <div action="#" class="d-flex w-100">
            <input
                type="text"
                class="form-control"
                placeholder="Type your message"
                [(ngModel)]="message"
                (keyup.enter)="sendMessage()"
            />
            <button type="button" class="btn btn-primary send-btn" [disabled]="!message" (click)="sendMessage()">
                Send
            </button>
        </div>
    </div>
</div>
